import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import ReactMarkdown from 'react-markdown';
import { handleError } from 'utils/common';

import { promiseGetRequisitionCandidates } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import EmptyAvatar from 'shared/assets/missing-user-picture.png';

import Content from 'components/Content';
import FormattedMoney from 'components/FormattedMoney';
import FormattedName from 'components/FormattedName';
import FontIcon from 'components/FontIcon';

import ConnectContainer from 'containers/ConnectContainer';

import duck from 'shared/store/app';

import styles from './Bookmark.scss';

const Bookmark = ({
  className,
  candidate,
  bookmarkId,
  deleteResource,
  handleOnClick,
  hideNames,
  candidate: { id: candidateId } = {},
}) => {
  const [noReqCand, setNoReqCand] = useState(null);

  const {
    summary,
    latestWorkHistoryJsonapi: latestWorkHistory = {},
    hiringStatus,
    firstName,
    lastName,
    picture: { xlarge = '' } = {},
  } = candidate || {};

  useEffect(() => {
    if (hideNames) {
      promiseGetRequisitionCandidates({ candidateId })
        .then((reqCandidates) => setNoReqCand(reqCandidates.length === 0))
        .catch((error) => {
          handleError(error);
          setNoReqCand(noReqCand);
        });
    }
  }, [candidateId, hideNames, noReqCand]);

  const {
    data: {
      attributes: { soldToCompanySizes = [], role: recentRole = null } = {},
      relationships: { salesPeriods: { data: salesPeriodsObjects = [] } = {} } = {},
    } = {},
    included = [],
  } = latestWorkHistory || {};

  const salesPeriods = included.filter((item) =>
    salesPeriodsObjects.map((sp) => sp.id).includes(item.id)
  );

  const {
    attributes: {
      quotaAmountCents: quotaAmount = '',
      dealsAverageCents: dealAverage = '',
      type = '',
    } = {},
  } = salesPeriods[0] || {};

  const picUrl = !xlarge || xlarge === '' ? EmptyAvatar : xlarge;

  const useBlur = xlarge && hideNames && (noReqCand || noReqCand === null);

  return (
    <div className={styles.mainContainer}>
      <div
        className={classnames(styles.SearchResult, className)}
        onClick={() => handleOnClick(candidateId)}
      >
        <div
          className={classnames(styles.resultImage, {
            [styles.blur]: useBlur,
          })}
        >
          <img src={picUrl} alt="user" />
        </div>

        <div className={styles.resultDetails}>
          <div className={styles.resultHeader}>
            <div className={styles.resultName}>
              {
                <FormattedName
                  useHideNames={true}
                  candidateId={candidateId}
                  firstName={firstName}
                  lastName={lastName}
                />
              }
            </div>

            <div className={styles.resultStatus}>
              {hiringStatus === 'active' && (
                <span className={styles.hiringStatus}>Actively Interviewing</span>
              )}
            </div>
          </div>

          {recentRole ? <div className={styles.resultPosition}>{recentRole}</div> : null}

          <Content className={styles.resultSummary}>
            <ReactMarkdown
              children={summary && summary.length > 275 ? `${summary.substr(0, 275)}...` : summary}
              className={styles.markdownSummary}
            />
          </Content>

          {['Non-Sales', 'B2C / Retail'].includes(recentRole) ? null : (
            <div className={styles.resultNumbers}>
              {quotaAmount ? (
                <div className={styles.resultNumberDetails}>
                  <FormattedMoney amount={quotaAmount} />
                  <span className={styles.numberLabel}>(Quota)</span>
                </div>
              ) : null}
              {dealAverage ? (
                <div className={styles.resultNumberDetails}>
                  <FormattedMoney amount={dealAverage} />
                  <span className={styles.numberLabel}>(Avg. Deal Size)</span>
                </div>
              ) : null}
              {soldToCompanySizes && soldToCompanySizes[0] ? (
                <div className={styles.resultNumberDetails}>
                  {soldToCompanySizes[0]}
                  <span className={styles.numberLabel}>
                    ({type === 'CSMSalesPeriod' ? 'Supports' : 'Sells To'})
                  </span>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className={styles.resultMatch}>
          <div
            title={'Remove'}
            onClick={(event) => {
              event.stopPropagation();
              deleteResource({
                slice: 'employerBookmarks',
                type: 'bookmarks',
                id: bookmarkId,
              });
            }}
            className={styles.resultBookmarked}
          >
            <FontIcon iconName="bookmark-24" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectContainer(duck)(Bookmark);
