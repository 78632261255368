import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';

import { redirectHomepage } from 'utils/navHelpers/index';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import ApplyForm from 'components/ApplyForm';

import { useDesiredRole } from 'hooks/useDesiredRole';

import duck from '../redux';

import Graphic from './components/Graphic';

import styles from './Apply.scss';

export const Apply = (props) => {
  const {
    actions: { applyStarted, saveUTMInfo },
  } = props;

  const location = useLocation();
  const { search } = location;

  const { preselectedRoles } = useDesiredRole();

  useEffect(() => {
    if (search) {
      const utmInfo = queryString.parse(search);
      saveUTMInfo({ utmInfo });
    }
  }, [saveUTMInfo, search]);

  const handleSubmit = async (values) => {
    const { firstName, lastName, email, linkedin, password, phone } = values;

    const applyData = {
      firstName,
      lastName,
      email,
      linkedinUrl: linkedin,
      password,
      phone,
      preselectedRole: preselectedRoles,
    };

    applyStarted({ applyData });
  };

  const { user, hasSeenApproved, isApproved } = props;

  const redirectPath = redirectHomepage(
    user,
    isApproved,
    hasSeenApproved,
    false,
    undefined,
    preselectedRoles
  );

  const title = 'Sales Jobs in Tech | Rainmakers';
  const metaDescription =
    'Sign up with Rainmakers to tap into a premium career network and connect with top tech companies in search of dynamic sales professionals. Various sales positions available!';
  const canonicalUrl = `https://${process.env.HOST}/apply-now/`;

  const helmetProps = {
    key: 'helmet',
    title,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: title },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <ReadyContainer key="Apply" className={styles.Container}>
        <div key="title" className={styles.Title}>
          LAND YOUR DREAM SALES JOB
        </div>
        <div key="form" className={styles.CandidateRegistration}>
          <Graphic />
          <div className={styles.applyFormContainer}>
            <ApplyForm handleSubmit={handleSubmit} oneLineName={true} />
          </div>
        </div>
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(Apply);
