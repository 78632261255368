import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AdminAuthHOC } from 'containers/HOC';
import { makeLikeParam } from 'utils/paramUtils';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import { useTableQuery } from 'hooks';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import ToolboxCandidatesTable from './components/ToolboxCandidatesTable';

import duck from './redux';

import styles from './ToolboxCandidatesPage.scss';

const route = '/toolbox/candidates/';

const ToolboxCandidatesPage = ({
  actions,
  candidateRequirements,
  children,
  isLoading,
  candidates,
  total,
  user,
  users = {},
  rainmakersAdmins = {},
  page = 1,
}) => {
  const {
    queryParams,
    loadMore,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
  } = useTableQuery(
    route,
    page,
    {
      applicationStatus: {
        type: 'array',
        defaultValue: ['approved', 'pending'],
      },
      lifecycleStatus: {
        type: 'array',
        defaultValue: [],
      },
      fullName: {
        type: 'string',
        defaultValue: null,
      },
      rainmakersAdminId: {
        type: 'array',
        defaultValue: [],
      },
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      userEmail: {
        type: 'string',
        defaultValue: null,
      },
      userUtmSource: {
        type: 'string',
        defaultValue: null,
      },
    },
    {
      type: 'candidates',
      slice: 'toolboxCandidates',
    },
    {
      includeSet: 'admin_search',
    },
    {
      fullName: makeLikeParam,
    }
  );

  const tableProps = {
    ...queryParams,
    actions,
    candidateRequirements,
    candidates,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
    isLoading,
    loadMore,
    rainmakersAdmins,
    total,
    user,
    users,
  };

  return (
    <>
      <Helmet key="helmet" title="Toolbox | Candidates" />

      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxCandidates" />

        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxCandidatesMainContent}>
              <ToolboxCandidatesTable {...tableProps} />
            </div>
          }
        />

        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxCandidatesPage));
