import React from 'react';
import { Helmet } from 'react-helmet-async';

import { AdminAuthHOC } from 'containers/HOC';

import ConnectContainer from 'containers/ConnectContainer';

import ReadyContainer from 'connectors/ReadyContainer';

import Sidebar from 'components/Sidebar';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';

import { useTableQuery } from 'hooks';
import ToolboxReferralsTable from './components/ToolboxReferralsTable';

import duck from './redux';

import styles from './ToolboxReferralsPage.scss';

const route = '/toolbox/referrals/';

const ToolboxReferralsPage = ({
  actions,
  candidateRequirements,
  children,
  isLoading,
  candidates,
  total,
  users = {},
  page = 1,
}) => {
  const {
    queryParams,
    loadMore,
    handleSearchInputChange,
    handleSearchFilterChange,
    handleSortChange,
  } = useTableQuery(
    route,
    page,
    {
      sortBy: {
        type: 'string',
        defaultValue: 'created_at_desc',
      },
      userReferredByEmail: {
        type: 'string',
        defaultValue: null,
      },
      userReferredBySource: {
        type: 'array',
        defaultValue: [],
      },
      userReferredByUserId: {
        type: 'array',
        defaultValue: [],
      },
    },
    {
      type: 'candidates',
      slice: 'toolboxCandidates',
    },
    {
      //user_referred: true,
      includeSet: 'admin_search',
    }
  );

  return (
    <>
      <Helmet key="helmet" title="Toolbox | Referrals" />
      <ReadyContainer key="readyContainer">
        <Sidebar type="toolbox" page="toolboxReferrals" />
        <LayoutWithoutSidebar
          content={
            <div className={styles.toolboxReferralsMainContent}>
              <ToolboxReferralsTable
                {...queryParams}
                actions={actions}
                candidateRequirements={candidateRequirements}
                candidates={candidates}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchFilterChange={handleSearchFilterChange}
                handleSortChange={handleSortChange}
                isLoading={isLoading}
                loadMore={loadMore}
                total={total}
                users={users}
              />
            </div>
          }
        />
        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(AdminAuthHOC()(ToolboxReferralsPage));
