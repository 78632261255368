/* eslint-disable react/self-closing-comp */
import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './PublicLandingPage.scss';

export const PublicLandingPage = ({ title, content, picture }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.mainPic}>
        {picture && <img src={picture} alt="company image" />}
        <div className={styles.textContainer}>
          <div className={styles.picTitle}>{title}</div>
          <div className={styles.picText}>
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        </div>
      </div>
      <svg
        id="rm-wavvy"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        viewBox="0 0 1024 1024"
        preserveAspectRatio="none"
      >
        <path
          id="shape-06"
          className={styles.st3}
          d="M1024,637.3c-31.4,16.8-67.3,26.7-109.7,26.7-277.8,0-277.8-425.9-555.5-425.9S108.4,438.4,0,564.2v459.8s1024,0,1024,0v-386.7Z"
        ></path>
        <path
          id="shape-05"
          className={styles.st4}
          d="M1024,842.4c-37.7,42.5-82.1,69.1-137.5,69.1-277.8,0-277.8-666.7-555.5-666.7S92.3,505.2,0,700.6v323.4s1024,0,1024,0v-181.6Z"
        ></path>
        <path
          id="shape-04"
          className={styles.st5}
          d="M0,1024h1024v-25.4c-24.5,13.5-51.6,21-82,21-277.8,0-277.8-629.8-555.5-629.8S127.2,736.7,0,915.6v108.4Z"
        ></path>
        <path
          id="shape-03"
          className={styles.st1}
          d="M-2.5,395.6c196.8,109.2,262.2,358.6,561.3,358.6s331.9-165,462.5-285.3l2.8-469H0l-2.5,395.6Z"
        ></path>
        <path
          id="shape-02"
          className={styles.st2}
          d="M0,159c225.7,151.6,280.6,589,597,589s314.1-212.6,427-392.8V0S0,0,0,0v159Z"
        ></path>
        <path
          id="shape-01"
          className={styles.st0}
          d="M0,0v116.5c166.7,174.8,243.1,492.3,520.4,492.3s348.3-296,503.6-473.8V0S0,0,0,0Z"
        ></path>
      </svg>
    </div>
  );
};
