import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { push } from 'connected-react-router';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { handleError } from 'utils/common';
import { getEntity } from 'api/apiEntity';

import duck from 'pages/AdminPortal/ToolboxEmployersPage/redux';

import ReadyContainer from 'connectors/ReadyContainer';
import Sidebar from 'components/Sidebar';

import { usePrevious } from 'hooks';

import { AdminAuthHOC, ModalHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import EmployerEditorEmployees from './components/EmployerEditorEmployees';
import EmployerEditorEmployee from './components/EmployerEditorEmployee';
import EmployerEditorIntegrations from './components/EmployerEditorIntegrations';
import EmployerEditorLandingPage from './components/EmployerEditorLandingPage';
import EmployerEditorPreview from './components/EmployerEditorPreview';
import EmployerEditorProfile from './components/EmployerEditorProfile';
import EmployerEditorSettings from './components/EmployerEditorSettings';
import EmployerEditorStats from './components/EmployerEditorStats';
import EmployerEditorTemplates from './components/EmployerEditorTemplates';

const ToolboxEmployerEditor = (props) => {
  const params = useParams();
  const { id, view } = params;
  const prevId = usePrevious(id);
  const prevView = usePrevious(view);
  const location = useLocation();

  useEffect(() => {
    const {
      actions: { pickerOptionsLoadStarted, tagsLoadStarted },
      employer: { attributes: { temporary = false } = {} } = {},
    } = props;

    if (!temporary) {
      doLoad(id);
    }

    tagsLoadStarted();
    pickerOptionsLoadStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextId = id;
    const nextView = view;
    const currId = prevId;
    const currView = prevView;

    if (nextId !== currId) {
      doLoad(nextId, false);
    }
    if (nextId === currId && nextView !== currView) {
      doLoad(nextId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, view]);

  const doLoad = async (id, updateOnly) => {
    const {
      actions: { entityLoadStarted, entityLoadDone },
      employer: { attributes: { companyId } = {} } = {},
    } = props;

    entityLoadStarted({
      type: 'rainmakers_admins',
      typeOverride: 'account_managers',
      slice: 'toolboxEmployers',
      params: {
        page: 1,
        includeSet: 'user',
        accountManager: true,
        state: false,
      },
    });

    entityLoadStarted({
      type: 'employers',
      slice: 'toolboxEmployers',
      id,
      params: {
        sortBy: 'created_at_desc',
      },
      updateOnly: true,
    });

    entityLoadStarted({
      type: 'employees',
      slice: 'toolboxEmployers',
      params: {
        sortBy: 'created_at_desc',
        employerId: id,
        page: 1,
        includeSet: 'employer_company',
      },
      updateOnly,
    });

    entityLoadStarted({
      type: 'requisition_candidates',
      slice: 'toolboxEmployers',
      params: {
        page: 1,
        employerId: id,
        sortBy: 'created_at_desc',
        includeSet: 'admin_toolbox',
        state: ['active', 'candidate_pass_pending'],
      },
      updateOnly,
    });

    entityLoadStarted({
      type: 'candidate_company_blocks',
      slice: 'toolboxEmployers',
      params: {
        page: 1,
        companyId,
        sortBy: 'company_name_asc',
        includeSet: 'candidate_creator',
      },
      updateOnly,
    });

    const cohortEmployersArgs = {
      type: 'cohort_employers',
      config: {
        params: {
          page: 1,
          employerId: id,
          cohortCategory: ['search', 'featured'],
          cohortState: ['open', 'active'],
          includeSet: 'cohort',
          sortBy: 'created_at',
          normalizeIt: true,
        },
      },
    };

    let cohortEmployersData;
    try {
      ({ data: cohortEmployersData } = await getEntity(cohortEmployersArgs));
    } catch (error) {
      handleError(error);
    }

    entityLoadDone({
      data: cohortEmployersData,
      type: 'cohort_employers',
      slice: 'toolboxEmployers',
      updateOnly,
    });
  };

  const handlePreviewButtonClick = () => {
    const {
      actions: { showModal },
      dispatch,
      modal: { originalUrl },
    } = props;

    const { state: { parent = null } = {} } = location;

    const employerId = id;

    const route = `/toolbox/employer/${employerId}/edit-preview`;

    if (!originalUrl) {
      dispatch(push(route));
    } else {
      showModal({
        key: 'EmployerEditorModal',
        originalUrl,
        parent,
        route,
      });
    }
  };

  const { children, employer } = props;

  const newProps = {
    ...props,
    match: { params }, // TODO: remove when child components are refactored
    handlePreviewButtonClick,
  };

  const sidebarView = view === 'edit-requisition' ? 'edit-requisitions' : view;

  const sidebarProps = {
    type: 'toolboxEmployerEditor',
    page: sidebarView,
    id: employer.id,
  };

  return isEmpty(employer) ? null : (
    <>
      <Helmet key="helmet" title="Employer Editor" />
      <ReadyContainer scroll key="readyContainer">
        <Sidebar {...sidebarProps} />

        <Switch>
          <Route exact path={'/toolbox/employer/:id/edit-profile'}>
            <EmployerEditorProfile {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-preview'}>
            <EmployerEditorPreview {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-integrations'}>
            <EmployerEditorIntegrations {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-landing-page'}>
            <EmployerEditorLandingPage {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-settings'}>
            <EmployerEditorSettings {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-stats'}>
            <EmployerEditorStats {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-employees'}>
            <EmployerEditorEmployees {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-employee/:employeeId?'}>
            <EmployerEditorEmployee {...newProps} />
          </Route>
          <Route exact path={'/toolbox/employer/:id/edit-templates'}>
            <EmployerEditorTemplates {...newProps} />
          </Route>
        </Switch>

        {children}
      </ReadyContainer>
    </>
  );
};

export default ConnectContainer(duck)(
  AdminAuthHOC()(
    ModalHOC({
      paginatorUrl: '/toolbox/employer/:id/edit-profile',
    })(ToolboxEmployerEditor)
  )
);
