import React from 'react';
import classnames from 'classnames';

import Content from 'components/Content';
import CandidateData from 'components/CandidateData';
import FormattedDate from 'components/FormattedDate';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';

import ToolboxCandidatesActionDropdown from 'pages/AdminPortal/ToolboxCandidatesPage/components/ToolboxCandidatesActionDropdown';

import ToolboxReferralsSearchBar from '../ToolboxReferralsSearchBar';

import styles from './ToolboxReferralsTable.scss';

const ToolboxReferralsTable = ({
  actions,
  actions: { showModal },
  candidates: { allIds = [], byId = {} } = {},
  handleSearchInputChange,
  handleSearchFilterChange,
  handleSortChange,
  isLoading,
  loadMore,
  userReferredByEmail,
  userReferredBySource,
  userReferredByUserId,
  sortBy,
  total,
  users: { byId: users = {} } = {},
}) => {
  const tableHeader = [
    {
      content: 'Name',
      headerClassName: styles.nameHeader,
      value: 'full_name',
      onClick: handleSortChange,
    },
    {
      content: 'Approval Status',
      headerClassName: styles.statusHeader,
    },
    {
      content: 'Referrer',
      headerClassName: styles.reffererHeader,
      value: 'created_at',
      onClick: handleSortChange,
    },
    {
      content: 'UTM Source',
      headerClassName: styles.utmSourcerHeader,
      value: 'created_at',
      onClick: handleSortChange,
    },
    {
      content: 'Source',
      headerClassName: styles.sourceHeader,
    },
    {
      content: '',
      headerClassName: styles.candActionHeader,
    },
  ];

  const openProfileModal = (id) =>
    showModal({
      key: 'CandidateEditorModal',
      parent: 'ToolboxCandidatesPage',
      route: `/toolbox/candidate/${id}/edit-preview`,
    });

  const makeCells = (id) => {
    const {
      [id]: candidate,
      [id]: {
        attributes: { applicationStatus, applicationStatusUpdatedAt, firstName, lastName } = {},
        relationships: { user: { data: userIds = [] } = {} } = {},
      } = {},
    } = byId || {};

    const {
      attributes: {
        confirmedAt,
        referredByEmail,
        referredByCode,
        referredBySource,
        utmSource,
      } = {},
    } = users[userIds[0]] || {};

    const isArchived = applicationStatus === 'archived';
    const isApproved = applicationStatus === 'approved';
    const isPending = applicationStatus === 'pending';

    const statusClassnames = classnames({
      [styles.approvedStatus]: isApproved,
      [styles.pendingStatus]: isPending,
      [styles.archivedStatus]: isArchived,
    });

    const profileUpdatedContent = applicationStatusUpdatedAt ? (
      <FormattedDate parse date={applicationStatusUpdatedAt} format="MMM D YYYY" />
    ) : null;

    const actionsProps = {
      actions,
      firstName,
      lastName,
      candidateId: id,
      userId: userIds[0] || '',
      isConfirmed: Boolean(confirmedAt),
      candidate: candidate.attributes,
    };

    const actionsContent =
      isApproved || isPending ? (
        <div className={styles.actionsContent}>
          <ToolboxCandidatesActionDropdown {...actionsProps} />
        </div>
      ) : (
        <div className={styles.actionsContent} />
      );

    return [
      <TableCell key={1}>
        <div className={styles.candidateContent}>
          <CandidateData onClick={() => openProfileModal(id)} candidate={candidate} />
        </div>
      </TableCell>,
      <TableCell key={2}>
        <div className={statusClassnames}>
          <div>{applicationStatus}</div>
          <Content className={styles.date}>{profileUpdatedContent}</Content>
        </div>
      </TableCell>,
      <TableCell key={3}>
        <div className={styles.referrerStatus}>{referredByEmail || referredByCode}</div>
      </TableCell>,

      <TableCell key={4}>
        <div className={styles.referrerStatus}>{utmSource}</div>
      </TableCell>,

      <TableCell key={5}>
        <div className={styles.sourceStatus}>{referredBySource}</div>
      </TableCell>,
      <TableCell noFlex key={7}>
        {actionsContent}
      </TableCell>,
    ];
  };

  const makeRows = (id) => (
    <TableRow id={id} key={id}>
      {makeCells(id)}
    </TableRow>
  );

  const tableContent = allIds
    .filter((id) => {
      const { [id]: { attributes: { temporary } = {} } = {} } = byId || {};

      return !temporary;
    })
    .map(makeRows);

  const titleContent = (
    <div className={styles.candidateHeader}>
      <div className={styles.candidateHeaderLeft}>
        <ToolboxReferralsSearchBar
          userReferredByEmail={userReferredByEmail}
          userReferredBySource={userReferredBySource}
          userReferredByUserId={userReferredByUserId}
          handleSearchInputChange={handleSearchInputChange}
          handleSearchFilterChange={handleSearchFilterChange}
        />
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: total > allIds.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.dashboardTitleContainer,
    },
    titleContent,
    tableContent,
    header: tableHeader,
    infiniteScrollProps,
    tableName: 'adminReferrals',
    sortBy,
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Referrals',
    isLoading,
  };

  return <Table {...tableProps} />;
};

export default ToolboxReferralsTable;
