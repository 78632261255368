import React, { Component } from 'react';
import classnames from 'classnames';

import ReactMarkdown from 'react-markdown';
import { ComponentErrorBoundaryHOC } from 'containers/HOC';

import Button from 'components/Button';
import Block from 'components/Block';
import Bolder from 'components/Bolder';
import Content from 'components/Content';
import FontIcon from 'components/FontIcon';

import styles from './ProfileSummary.scss';

export class ProfileSummary extends Component {
  constructor(props) {
    super(props);

    this.state = { showMore: undefined, hide: true };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.summaryContainer) {
        this.setState({
          showMore: this.summaryContainer.clientHeight <= 80 ? undefined : false,
          hide: false,
        });
      }
    }, 1);
  }

  componentDidUpdate(prevProps) {
    const { summary } = this.props;
    if (prevProps.summary !== summary) {
      this.resetHide();

      setTimeout(() => {
        if (this.summaryContainer) {
          this.setState({
            showMore: this.summaryContainer.clientHeight <= 80 ? undefined : false,
            hide: false,
          });
        }
      }, 1);
    }
  }

  resetHide = () => this.setState({ showMore: undefined, hide: true });

  render() {
    const {
      summary: origSummary,
      salesExperienceMonths,
      recentRole,
      recentGrad,
      awards,
      attributes,
      isPrint,
    } = this.props;

    const { showMore, hide } = this.state;

    const isCSM = recentRole === 'Account Management (CSM/AM)';

    const isNonSales = ['Non-Sales', 'B2C / Retail'].includes(recentRole);

    const salesExpYears = salesExperienceMonths / 12;

    const salesExpDescriptor = isCSM ? 'customer-facing experience' : 'in sales';

    const salesExpString = `${Math.floor(salesExpYears)}+ years ${salesExpDescriptor}`;

    const salesExpContent = (!recentGrad || recentRole) &&
      !isNonSales &&
      salesExperienceMonths !== null &&
      salesExperienceMonths >= 12 && (
        <div className={styles.summaryItem}>
          <FontIcon className={styles.calendarIcon} iconName="calendar" /> {salesExpString}
        </div>
      );

    const summary = origSummary ? origSummary.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '') : '';

    const showMoreButton = isPrint ? null : showMore ? (
      <Button onClick={() => this.setState({ showMore: false })} tertiary={true}>
        Show less <FontIcon className={styles.showMoreIcon} iconName="caret-up" />
      </Button>
    ) : (
      <Button
        onClick={() => this.setState({ showMore: true })}
        tertiary={true}
        className={styles.showMoreButton}
      >
        Show more <FontIcon className={styles.showMoreIcon} iconName="caret-down" />
      </Button>
    );

    const showMoreFinal = showMore === undefined ? null : showMoreButton;

    const showMoreFade = showMore || isPrint ? null : <div className={styles.summaryClosedFade} />;

    const showMoreFadeFinal = showMore === undefined ? null : showMoreFade;

    const summaryContent = summary && (
      <>
        <div
          ref={(ref) => {
            this.summaryContainer = ref;
          }}
          className={classnames(styles.summary, {
            [styles.summaryClosed]: showMore === false && !isPrint,
            [styles.summaryHide]: hide && !isPrint,
          })}
        >
          <ReactMarkdown children={summary} className={styles.markdownSummary} />
        </div>
        {showMoreFadeFinal}
        {showMoreFinal}
      </>
    );

    if (!summaryContent && !salesExpContent) return null;

    return (
      <Block
        addWhiteBG={true}
        title={
          <Content>
            <Bolder>SUMMARY</Bolder>
          </Content>
        }
        secondaryTitle={true}
        className={styles.blockContainer}
        data-testid="ProfileSummary"
      >
        {salesExpContent}
        {summaryContent}
        {attributes}
        {awards}
      </Block>
    );
  }
}

export default ComponentErrorBoundaryHOC()(ProfileSummary);
