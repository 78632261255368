import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';

import { ComponentErrorBoundaryHOC } from 'containers/HOC';
import ConnectContainer from 'containers/ConnectContainer';

import { promisePickerOptions } from 'connectors/Defaults/promises';

import { useSize } from 'hooks';

import duck from '../redux';

import Header from '../CandidateProfile/components/ProfileHeader';
import Attributes from '../CandidateProfile/components/ProfileAttributes';
import Summary from '../CandidateProfile/components/ProfileSummary';
import RecentSalesInfo from '../CandidateProfile/components/ProfileRecentSalesInfo';
import ProfileSales from '../CandidateProfile/components/ProfileSales';
import Recruitment from '../CandidateProfile/components/ProfileRecruitment';
import Education from '../CandidateProfile/components/ProfileEducation';
import QAndA from '../CandidateProfile/components/ProfileQAndA';
import Awards from '../CandidateProfile/components/ProfileAwards';

import styles from './CandidateProfilePrint.scss';

const CandidateProfilePrint = (props) => {
  const {
    candidateProfile: candidate,
    className,
    match: { params: { id } = {} } = {},
    modal,
    requestedCandidates = [],
    setScrollDivRef,
    useHideNames,
    useBlankHideNames,
  } = props;

  const [pickerOptions, setPickerOptions] = useState({});

  const profileContainerRef = useRef(null);
  const size = useSize(profileContainerRef);

  useEffect(() => {
    const { width, height } = size || {};
    document.getHeight = () => height;
    document.getWidth = () => width;
  }, [size]);

  const {
    candidateEducations,
    candidateRequirement,
    closingExperienceMonths,
    hiringStatus,
    id: candidateId,
    latestWorkHistoryJsonapi: latestWorkHistory = {},
    managementExperience,
    outsideSalesExperience,
    qAndA,
    recentGrad: recentGradValue,
    resume,
    salesExperienceMonths,
    socialMedia,
    soldCLevel,
    soldSaas,
    startupExperience,
    summary,
    vimeoVideo: { id: vimeoVideoId, videoStatus } = {},
    workHistories = [],
  } = candidate || {};

  const {
    data: {
      attributes: {
        salesPeriods: mainSalesPeriods,
        role: recentRole = null,
        soldToDepartments: recentDepartments = [],
        soldToCompanySizes: recentSizes = [],
        soldToIndustries: recentIndustries = [],
        soldToCompanies: recentSoldToCompanies = [],
        territories: recentTerritories = [],
        productTypes: recentProductTypes = [],
      } = {},
      relationships: { salesPeriods: { data: salesPeriodsObjects = [] } = {} } = {},
    } = {},
    included = [],
  } = latestWorkHistory || {};

  const salesPeriodsIds = salesPeriodsObjects.map((sp) => sp.id);

  useEffect(() => {
    promisePickerOptions()
      .then(setPickerOptions)
      .finally(() => {
        window.print();
      });
  }, []);

  const salesPeriods =
    mainSalesPeriods ||
    included.filter((item) => salesPeriodsIds.includes(item.id)).map((sp) => sp.attributes);

  const latestSalesPeriod = salesPeriods && salesPeriods.length > 0 ? salesPeriods[0] : {};

  const {
    quotaAmountCents: recentQuotaAmount = null,
    dealsAverageCents: recentDealAverage = null,
    saleCycleDays: recentSaleCycleDays = null,
    leadsGeneratedPercentage: recentLeadsGenPercentage = null,
    dealsVolumeCents: recentDealVolume = null,
    renewalRetentionPercentage: recentRenewalRetentionPercentage = null,
    companiesSupported: recentCompaniesSupported = null,
  } = latestSalesPeriod || {};

  const profileHeaderProps = {
    candidate,
    currentPosition: workHistories[0],
    socialMedia,
    resume,
    hasResume: resume !== '/resumes/original/missing.png',
    hiringStatus,
    videoLink: videoStatus === 'available' ? `/intro-video/${vimeoVideoId}/` : '',
    handleShowTheater: () => {},
    alwaysUseHideName: requestedCandidates.includes(candidateId),
    useHideNames,
    useBlankHideNames,
    id,
    isPrint: true,
  };

  const recentGrad =
    recentGradValue &&
    (['Non-Sales', 'B2C / Retail'].includes(recentRole) || workHistories.length === 0);

  const profileAttributesProps = {
    recentGrad,
    recentRole,
    salesExperienceMonths,
    closingExperienceMonths,
    soldSaas,
    soldCLevel,
    outsideSalesExperience,
    startupExperience,
    managementExperience,
  };

  const profileSummaryProps = {
    summary,
    recentRole,
    recentGrad,
    salesExperienceMonths,
    awards: <Awards qAndA={qAndA} />,
    attributes: <Attributes {...profileAttributesProps} />,
    isPrint: true,
  };

  const profileRecentSalesInfoProps = {
    recentCompaniesSupported,
    recentDealVolume,
    recentQuotaAmount,
    recentDealAverage,
    recentSaleCycleDays,
    recentLeadsGenPercentage,
    recentRenewalRetentionPercentage,
    recentSoldToCompanies,
    recentTerritories,
    recentProductTypes,
    recentDepartments,
    recentSizes,
    recentIndustries,
    recentRole,
    isPrint: true,
  };

  const profileRecruitmentProps = {
    candidateRequirement,
    pickerOptions: pickerOptions || {},
  };

  const mainClassnames = classnames(
    {
      [styles.viewCandidate]: isEmpty(modal),
      [styles.viewCandidateModal]: !isEmpty(modal),
    },
    className
  );

  return (
    <div className={mainClassnames} data-testid="CandidateProfile" ref={profileContainerRef}>
      <div ref={setScrollDivRef} className={styles.scrollDiv}>
        <div className={styles.contentContainer}>
          <Header {...profileHeaderProps} />
          <Summary {...profileSummaryProps} />

          {recentGrad ? (
            <>
              <RecentSalesInfo {...profileRecentSalesInfoProps} />
              <Education candidateEducations={candidateEducations} recentGrad={recentGrad} />
              <ProfileSales workHistories={workHistories} isPrint={true} />
              <Recruitment {...profileRecruitmentProps} />
            </>
          ) : (
            <>
              <RecentSalesInfo {...profileRecentSalesInfoProps} />
              <Recruitment {...profileRecruitmentProps} />
              <ProfileSales workHistories={workHistories} isPrint={true} />
              <Education candidateEducations={candidateEducations} recentGrad={recentGrad} />
            </>
          )}

          <QAndA qAndA={qAndA} />
        </div>
      </div>
    </div>
  );
};

export default ComponentErrorBoundaryHOC()(ConnectContainer(duck)(CandidateProfilePrint));
