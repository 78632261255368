const MANAGE_SIDEBAR_LINKS = [
  {
    to: '/manage/dashboard',
    title: 'Dashboard',
    icon: 'gauge',
    active: 'dashboard',
  },
  {
    to: '/manage/reqs',
    title: 'Manage Reqs',
    icon: 'list',
    active: 'manageReqs',
  },
];

const REQUISITION_SIDEBAR_LINKS = [
  {
    to: '/requisitions/:id/preview',
    title: 'Preview',
    modalKey: 'ReqDetailModal',
    icon: 'resume',
    active: 'preview',
    disableable: true,
  },
  {
    to: '/requisitions/:id/edit',
    title: 'Details',
    modalKey: 'ReqDetailModal',
    icon: 'list',
    active: ['edit', 'view'],
  },
  {
    to: '/requisitions/:id/requirements',
    title: 'Requirements',
    modalKey: 'ReqDetailModal',
    icon: 'gauge',
    active: 'requirements',
    disableable: true,
  },
  {
    to: '/requisitions/:id/integrations',
    title: 'Integrations',
    modalKey: 'ReqDetailModal',
    icon: 'zap',
    active: 'integrations',
    disableable: true,
    featureFlags: [
      {
        type: 'employer',
        attributes: ['greenhouseReady'],
      },
    ],
  },
];

const REQUISITION_CANDIDATE_SIDEBAR_LINKS = [
  {
    to: '/requisition_candidates/:id/requisition_opening/preview',
    title: 'Preview',
    modalKey: 'ReqDetailModal',
    icon: 'resume',
    active: 'reqPreview',
    disableable: true,
    // }, {
    //   to: '/requisition_candidates/:id/requisition_opening/edit',
    //   title: 'Details',
    //   modalKey: 'ReqDetailModal',
    //   icon: 'list',
    //   active: 'reqDetails',
  },
];

const CANDIDATE_SIDEBAR_LINKS = [
  {
    to: '/candidates/:id/profile',
    modalKey: 'CandidateProfileModal',
    title: 'Profile',
    icon: 'candidate',
    active: 'profile',
  },
  {
    to: '/candidates/:id/resume',
    modalKey: 'CandidateProfileModal',
    title: 'Resume',
    icon: 'resume',
    active: 'resume',
  },
];

const CANDIDATE_SEARCH_SIDEBAR_LINKS = [
  {
    to: '/featured',
    title: 'Featured',
    icon: 'search',
    active: 'candidateSearch',
  },
  {
    to: '/matches',
    title: 'Matches',
    icon: 'star',
    active: 'featuredCandidates',
  },
  {
    to: '/saved',
    title: 'Saved',
    icon: 'bookmark-16',
    active: 'candidateBookmarks',
  },
];

const EMPLOYEE_EDIT_EMPLOYEE_LINKS = [
  {
    to: '/employee/edit-employees/:id/profile',
    title: 'Profile',
    icon: 'candidate',
    modalKey: 'EmployeeEditorModal',
    active: 'profile',
  },
  {
    to: '/employee/edit-employees/:id/settings',
    title: 'Settings',
    icon: 'cog',
    modalKey: 'EmployeeEditorModal',
    active: 'settings',
  },
];

const EMPLOYEE_STATUS_UNAPPROVED_SIDEBAR_LINKS = [
  {
    to: '/employer-signup-status',
    title: 'Application Status',
    mobileTitle: 'App Status',
    icon: 'gauge',
    active: 'employerSignupStatus',
  },
  {
    to: '/employee/edit-personal',
    title: 'Personal Profile',
    mobileTitle: 'Personal',
    icon: 'candidate',
    active: 'edit-personal',
  },
  {
    to: '/employee/edit-company',
    title: 'Company Profile',
    mobileTitle: 'Company',
    icon: 'employer',
    active: 'edit-company',
  },
  {
    to: '/employee/edit-preview',
    title: 'Company Preview',
    mobileTitle: 'Preview',
    icon: 'resume',
    active: 'edit-preview',
  },
  {
    to: '/employee/edit-employees',
    title: 'Employees',
    mobileTitle: 'Employees',
    icon: 'cohort',
    active: 'edit-employees',
  },
];

const EMPLOYEE_STATUS_APPROVED_SIDEBAR_LINKS = [
  {
    to: '/employee/edit-personal',
    title: 'Personal Profile',
    mobileTitle: 'Personal',
    icon: 'candidate',
    active: 'edit-personal',
  },
  {
    to: '/employee/edit-company',
    title: 'Company Profile',
    mobileTitle: 'Company',
    icon: 'employer',
    active: 'edit-company',
  },
  {
    to: '/employee/edit-preview',
    title: 'Company Preview',
    mobileTitle: 'Preview',
    icon: 'resume',
    active: 'edit-preview',
  },
  {
    to: '/employee/edit-employees',
    title: 'Employees',
    mobileTitle: 'Employees',
    icon: 'cohort',
    active: 'edit-employees',
  },
  {
    to: '/employee/edit-integrations',
    title: 'Integrations',
    mobileTitle: 'Integrations',
    icon: 'zap',
    active: 'edit-integrations',
  },
];

const CANDIDATE_SETTINGS_SIDEBAR_LINKS = [
  {
    to: '/candidate/privacy-settings',
    title: 'Privacy',
    icon: 'lock',
    active: 'privacy-settings',
  },
  {
    to: '/candidate/email-settings',
    title: 'Email notifications',
    mobileTitle: 'Notifications',
    icon: 'cog',
    active: 'email-settings',
    // }, {
    //   to: '/candidate/password-settings',
    //   title: 'Password',
    //   icon: 'gauge',
    //   active: 'password-settings',
  },
  {
    to: '/candidate/referrals',
    title: 'Referrals',
    icon: 'cohort',
    active: 'referrals',
  },
];

const CANDIDATE_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/candidate/edit-preview',
    title: 'Preview Profile',
    mobileTitle: 'Preview',
    icon: 'resume',
    active: 'edit-preview',
  },
  {
    to: '/candidate/edit-personal',
    title: 'Personal Info',
    mobileTitle: 'Personal',
    icon: 'candidate',
    active: 'edit-personal',
  },
  {
    to: '/candidate/edit-work',
    title: 'Work History',
    mobileTitle: 'Work',
    icon: 'company',
    active: 'edit-work',
  },
  {
    to: '/candidate/edit-sales',
    title: 'Sales History',
    mobileTitle: 'Sales',
    icon: 'cash',
    active: 'edit-sales',
  },
  {
    to: '/candidate/edit-education',
    title: 'Education',
    icon: 'education',
    active: 'edit-education',
  },
  {
    to: '/candidate/edit-recruiting',
    title: 'Recruiting Report',
    mobileTitle: 'Recruiting',
    icon: 'zap',
    active: 'edit-recruiting',
  },
  {
    to: '/candidate/intro-video/',
    title: 'Intro Video',
    mobileTitle: 'Video',
    icon: 'handshake',
    active: 'edit-video',
    isNew: true,
  },
];

const TOOLBOX_SIDEBAR_LINKS = [
  {
    to: '/toolbox/candidates/',
    title: 'Candidates',
    icon: 'candidate',
    active: 'toolboxCandidates',
  },
  {
    to: '/toolbox/referrals/',
    title: 'Referrals',
    icon: 'referral',
    active: 'toolboxReferrals',
  },
  {
    to: '/toolbox/employers/',
    title: 'Employers',
    icon: 'employer',
    active: 'toolboxEmployers',
  },
  {
    to: '/toolbox/approaches/',
    title: 'Approaches',
    icon: 'handshake',
    active: 'toolboxApproaches',
  },
  {
    to: '/toolbox/requisitions/',
    title: 'Requisitions',
    icon: 'resume',
    active: 'toolboxRequisitions',
  },
  {
    to: '/toolbox/cohorts/',
    title: 'Cohorts',
    icon: 'cohort',
    active: 'toolboxCohorts',
  },
  {
    to: '/toolbox/cohort-requests/',
    title: 'Cohort Requests',
    icon: 'star',
    active: 'toolboxBatchRequests',
  },
  {
    to: '/toolbox/leads/',
    title: 'Leads',
    icon: 'lead',
    active: 'toolboxLeads',
  },
  {
    to: '/toolbox/education-orgs/',
    title: 'Education Orgs',
    mobileTitle: 'Institutions',
    icon: 'education',
    active: 'toolboxEducationOrgs',
  },
  {
    to: '/toolbox/companies/',
    title: 'Companies',
    icon: 'company',
    active: 'toolboxCompanies',
  },
  {
    to: '/toolbox/admins/',
    title: 'Admins',
    icon: 'pied-piper',
    active: 'toolboxAdmins',
  },
];

const TOOLBOX_APPROACH_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/approach/:id/messages',
    modalKey: 'ApproachEditorModal',
    title: 'Messages',
    mobileTitle: 'Messages',
    icon: 'messages',
    active: 'messages',
  },
  {
    to: '/toolbox/approach/:id/candidate',
    modalKey: 'ApproachEditorModal',
    title: 'Candidate',
    mobileTitle: 'Candidate',
    icon: 'candidate',
    active: 'candidate',
  },
  {
    to: '/toolbox/approach/:id/preview',
    modalKey: 'ApproachEditorModal',
    title: 'Opening Preview',
    mobileTitle: 'Opening',
    icon: 'resume',
    active: 'preview',
  },
  {
    to: '/toolbox/approach/:id/details',
    modalKey: 'ApproachEditorModal',
    title: 'Edit Requisition',
    mobileTitle: 'Edit',
    icon: 'list',
    active: 'details',
  },
];

const TOOLBOX_ADMIN_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/admin/:id/edit-profile',
    modalKey: 'AdminEditorModal',
    title: 'Profile',
    mobileTitle: 'Profile',
    icon: 'candidate',
    active: 'edit-profile',
  },
  {
    to: '/toolbox/admin/:id/edit-settings',
    modalKey: 'AdminEditorModal',
    title: 'Settings',
    mobileTitle: 'Settings',
    icon: 'cog',
    active: 'edit-settings',
  },
  {
    to: '/toolbox/admin/:id/suggestion-sets',
    modalKey: 'AdminEditorModal',
    title: 'Suggestion Sets',
    mobileTitle: 'Suggestion Sets',
    icon: 'cohort',
    active: 'suggestion-sets',
  },
  {
    to: '/toolbox/admin/:id/referrals',
    modalKey: 'AdminEditorModal',
    title: 'Referrals',
    mobileTitle: 'Referrals',
    icon: 'cohort',
    active: 'referrals',
  },
];

const TOOLBOX_CANDIDATE_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/candidate/:id/edit-preview',
    modalKey: 'CandidateEditorModal',
    title: 'Preview',
    mobileTitle: 'Preview',
    icon: 'resume',
    active: 'edit-preview',
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-personal',
    modalKey: 'CandidateEditorModal',
    title: 'Personal Info',
    mobileTitle: 'Personal',
    icon: 'candidate',
    active: 'edit-personal',
  },
  {
    to: '/toolbox/candidate/:id/edit-work',
    modalKey: 'CandidateEditorModal',
    title: 'Work History',
    mobileTitle: 'Work',
    icon: 'company',
    active: 'edit-work',
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-sales',
    modalKey: 'CandidateEditorModal',
    title: 'Sales Performance',
    mobileTitle: 'Sales',
    icon: 'cash',
    active: 'edit-sales',
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-education',
    modalKey: 'CandidateEditorModal',
    title: 'Education',
    icon: 'education',
    active: 'edit-education',
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-skills',
    modalKey: 'CandidateEditorModal',
    title: 'Recruiting Report',
    mobileTitle: 'Recruiting',
    icon: 'zap',
    active: 'edit-skills',
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-settings',
    modalKey: 'CandidateEditorModal',
    title: 'Settings',
    icon: 'cog',
    active: 'edit-settings',
    needBorder: true,
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-stats',
    modalKey: 'CandidateEditorModal',
    title: 'Stats',
    icon: 'gauge',
    active: 'edit-stats',
    disableable: true,
  },
  {
    to: '/toolbox/candidate/:id/edit-intro',
    modalKey: 'CandidateEditorModal',
    title: 'Intro Video',
    icon: 'handshake',
    active: 'edit-intro',
    disableable: true,
    isNew: true,
  },
];

const TOOLBOX_EDUCATION_ORG_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/education-org/:id/edit-profile',
    modalKey: 'EducationOrgEditorModal',
    title: 'Profile',
    icon: 'education',
    active: 'edit-profile',
  },
  {
    to: '/toolbox/education-org/:id/edit-settings',
    modalKey: 'EducationOrgEditorModal',
    title: 'Settings',
    icon: 'cog',
    active: 'edit-settings',
    needBorder: true,
    disableable: true,
  },
  {
    to: '/toolbox/education-org/:id/edit-stats',
    modalKey: 'EducationOrgEditorModal',
    title: 'Stats',
    icon: 'gauge',
    active: 'edit-stats',
    disableable: true,
  },
];

const TOOLBOX_COMPANY_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/company/:id/edit-profile',
    modalKey: 'CompanyEditorModal',
    title: 'Company Profile',
    icon: 'employer',
    active: 'edit-profile',
  },
  {
    to: '/toolbox/company/:id/edit-settings',
    modalKey: 'CompanyEditorModal',
    title: 'Settings',
    icon: 'cog',
    active: 'edit-settings',
    needBorder: true,
    disableable: true,
  },
  {
    to: '/toolbox/company/:id/edit-stats',
    modalKey: 'CompanyEditorModal',
    title: 'Stats',
    icon: 'gauge',
    active: 'edit-stats',
    disableable: true,
  },
];

const TOOLBOX_EMPLOYER_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/employer/:id/edit-preview',
    modalKey: 'EmployerEditorModal',
    title: 'Preview',
    mobileTitle: 'Preview',
    icon: 'resume',
    active: 'edit-preview',
    disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-profile',
    modalKey: 'EmployerEditorModal',
    title: 'Company',
    mobileTitle: 'Company',
    icon: 'employer',
    active: 'edit-profile',
    // }, {
    // to: '/toolbox/employer/:id/edit-requisitions',
    // modalKey: 'EmployerEditorModal',
    // title: 'Requisitions',
    // mobileTitle: 'Reqs',
    // icon: 'list',
    // active: 'edit-requisitions',
    // disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-employees',
    modalKey: 'EmployerEditorModal',
    title: 'Employees',
    icon: 'cohort',
    active: ['edit-employees', 'edit-employee'],
    disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-templates',
    modalKey: 'EmployerEditorModal',
    title: 'Templates',
    icon: 'list',
    active: 'edit-templates',
    disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-integrations',
    modalKey: 'EmployerEditorModal',
    title: 'Integrations',
    icon: 'zap',
    active: 'edit-integrations',
    disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-landing-page',
    modalKey: 'EpmployerLandingPage',
    title: 'Landing Page',
    icon: 'zap',
    active: 'edit-landing-page',
    disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-settings',
    modalKey: 'EmployerEditorModal',
    title: 'Settings',
    icon: 'cog',
    active: 'edit-settings',
    needBorder: true,
    disableable: true,
  },
  {
    to: '/toolbox/employer/:id/edit-stats',
    modalKey: 'EmployerEditorModal',
    title: 'Stats',
    icon: 'gauge',
    active: 'edit-stats',
    disableable: true,
  },
];

const TOOLBOX_COHORT_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/cohort/:id/edit-main',
    modalKey: 'CohortEditorModal',
    title: 'Settings',
    icon: 'cog',
    active: 'edit-main',
  },
  {
    to: '/toolbox/cohort/:id/edit-employers',
    modalKey: 'CohortEditorModal',
    title: 'Employers',
    icon: 'employer',
    active: 'edit-employers',
    disableable: true,
  },
  {
    to: '/toolbox/cohort/:id/edit-candidates',
    modalKey: 'CohortEditorModal',
    title: 'Candidates',
    icon: 'cohort',
    active: 'edit-candidates',
    disableable: true,
  },
];

const TOOLBOX_REQUISITION_EDITOR_SIDEBAR_LINKS = [
  {
    to: '/toolbox/requisition/:id/view',
    modalKey: 'RequisitionEditorModal',
    title: 'Preview',
    icon: 'resume',
    active: 'view',
  },
  {
    to: '/toolbox/requisition/:id/requisition-opening',
    modalKey: 'RequisitionEditorModal',
    title: 'Requisition Opening',
    icon: 'list',
    active: 'requisition-opening',
  },
  {
    to: '/toolbox/requisition/:id/requisition-requirement',
    modalKey: 'RequisitionEditorModal',
    title: 'Requisition Requirement',
    icon: 'gauge',
    active: 'requisition-requirement',
    disableable: true,
  },
  {
    to: '/toolbox/requisition/:id/suggestion-sets',
    modalKey: 'RequisitionEditorModal',
    title: 'Suggestion Sets',
    icon: 'cohort',
    active: 'suggestion-sets',
    disableable: true,
  },
  // {
  //   to: '/toolbox/requisition/:id/es-query',
  //   modalKey: 'RequisitionEditorModal',
  //   title: 'ES Query',
  //   icon: 'zap',
  //   active: 'es-query',
  //   disableable: true,
  // },
  {
    to: '/toolbox/requisition/:id/suggestions',
    modalKey: 'RequisitionEditorModal',
    title: 'Suggestions',
    icon: 'star',
    active: 'suggestions',
    disableable: true,
  },
  {
    to: '/toolbox/requisition/:id/requisition-candidates',
    modalKey: 'RequisitionEditorModal',
    title: 'Candidates',
    icon: 'candidate',
    active: 'requisition-candidates',
    disableable: true,
  },
  {
    to: '/toolbox/requisition/:id/requisition-integrations',
    modalKey: 'RequisitionEditorModal',
    title: 'Integrations',
    icon: 'cog',
    active: 'requisition-integrations',
    disableable: true,
    featureFlags: [
      {
        type: 'employer',
        attributes: ['greenhouseReady'],
      },
    ],
  },
];

const SIDEBAR_LINKS = {
  manage: MANAGE_SIDEBAR_LINKS,
  employerRequisitionEditor: REQUISITION_SIDEBAR_LINKS,
  requisitionCandidate: REQUISITION_CANDIDATE_SIDEBAR_LINKS,
  candidate: CANDIDATE_SIDEBAR_LINKS,
  search: CANDIDATE_SEARCH_SIDEBAR_LINKS,
  employerEditor: EMPLOYEE_EDIT_EMPLOYEE_LINKS,
  employeeStatusUnapproved: EMPLOYEE_STATUS_UNAPPROVED_SIDEBAR_LINKS,
  employeeStatusApproved: EMPLOYEE_STATUS_APPROVED_SIDEBAR_LINKS,
  toolbox: TOOLBOX_SIDEBAR_LINKS,
  toolboxAdminEditor: TOOLBOX_ADMIN_EDITOR_SIDEBAR_LINKS,
  toolboxApproachEditor: TOOLBOX_APPROACH_EDITOR_SIDEBAR_LINKS,
  toolboxCandidateEditor: TOOLBOX_CANDIDATE_EDITOR_SIDEBAR_LINKS,
  toolboxCohortEditor: TOOLBOX_COHORT_EDITOR_SIDEBAR_LINKS,
  toolboxCompanyEditor: TOOLBOX_COMPANY_EDITOR_SIDEBAR_LINKS,
  toolboxEducationOrgEditor: TOOLBOX_EDUCATION_ORG_EDITOR_SIDEBAR_LINKS,
  toolboxEmployerEditor: TOOLBOX_EMPLOYER_EDITOR_SIDEBAR_LINKS,
  toolboxRequisitionEditor: TOOLBOX_REQUISITION_EDITOR_SIDEBAR_LINKS,
  candidateEditor: CANDIDATE_EDITOR_SIDEBAR_LINKS,
  candidateSettings: CANDIDATE_SETTINGS_SIDEBAR_LINKS,
};

export default SIDEBAR_LINKS;
