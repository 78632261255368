import React, { Component, Fragment, createRef } from 'react';
import ReactMarkdown from 'react-markdown';

import { handleError } from 'utils/common';
import { patchEntity, postNestedEntity } from 'api/apiEntity';

import { MILITARY_STATUS_SOURCE, GENDER_SOURCE } from 'connectors/Defaults/constants';

import Block from 'components/Block';
import Button from 'components/Button';
import CandidateImageUploader from 'components/CandidateImageUploader';
import Content from 'components/Content';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import Input from 'components/Input';
import InputRichText from 'components/InputRichText';
import InputSelect from 'components/InputSelect';
import InputFile from 'components/InputFile';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import TableOverlay from 'components/TableOverlay';
import BullhornPushButton from 'components/BullhornPushButton';

import { QuickEditor } from './components/QuickEditor';

import styles from './CandidateEditorPersonal.scss';

const SUMMARY_SIZE_SHORTEST = 500;
const SUMMARY_SIZE_LONGEST = 720;

const summarySizeColors = {
  Short: 'orange',
  Good: 'green',
  Long: 'red',
};

class CandidateEditorPersonal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeTypeError: null,
    };
  }

  summarySizeMeasurementRef = createRef();

  handleResumeUploadButtonClick = async (event) => {
    event.preventDefault();
    const {
      actions: { resourceUpdate, nestedEntityLoadStarted },
      match: { params: { id } = {} } = {},
    } = this.props;

    this.setState({
      resumeTypeError: null,
      resumeSaving: true,
    });

    const resume = event.target.files[0];

    if (resume.size > 10000000) {
      return this.setState({
        resumeTypeError: 'File size must be less than 10 MB',
      });
    }

    if (resume.type === 'application/pdf') {
      const data = new FormData();
      data.append('data[attributes][resume]', resume);
      data.append('data[type]', 'candidate');
      data.append('data[id]', id);

      const args = {
        type: 'candidates',
        id,
        data,
        config: {
          params: {
            normalizeIt: true,
            includeSet: 'admin_search',
          },
        },
      };

      let byId;
      let allIds;
      try {
        ({ data: { entities: { candidate: { byId = {}, allIds = [] } = {} } = {} } = {} } =
          await patchEntity(args));
      } catch (error) {
        handleError(error);
      }

      resourceUpdate({
        resource: byId[allIds[0]],
        id: allIds[0],
        type: 'candidates',
        slice: 'toolboxCandidates',
      });

      const postArgs = {
        id,
        type: 'candidates',
        nestedType: 'parse_resume',
      };

      try {
        await postNestedEntity(postArgs);
      } catch (error) {
        handleError(error);
      }

      nestedEntityLoadStarted({
        type: 'candidates',
        id,
        nestedType: 'work_histories',
        slice: 'toolboxCandidates',
        params: {
          sortBy: 'end_date_desc_nulls_first,start_date_desc',
          includeSet: 'company_sales_periods',
        },
      });

      nestedEntityLoadStarted({
        type: 'candidates',
        id,
        nestedType: 'candidate_educations',
        slice: 'toolboxCandidates',
        params: {
          sortBy: 'finished_at_desc_nulls_first,started_at_desc',
          includeSet: 'education_organization',
        },
      });

      this.setState({ resumeSaving: false });
    } else {
      this.setState({
        resumeTypeError: 'Resume must be a PDF',
        resumeSaving: false,
      });
    }
  };

  render() {
    const {
      actions: { patchUserResource },
      candidate,
      candidate: {
        attributes: {
          closingExperienceMonths = '',
          firstName = '',
          gender = '',
          lastName = '',
          location = '',
          militaryStatus = '',
          picture: { xlarge: pictureUrl = '' } = {},
          resume = '',
          salesExperienceMonths = '',
          socialMedia: {
            linkedin: { url: linkedinUrl = '' } = {},
            twitter: { username: twitterUsername = '' } = {},
          } = {},
          summary = '',
          temporary: disabled = false,
        } = {},
        relationships: {
          user: { data: userIds = [] } = {},
          workHistory: { data: workHistoryIds = [] } = {},
          candidateRequirement: { data: candidateRequirementIds = [] } = {},
        } = {},
      } = {},
      candidateRequirements: { byId: candidateRequirementsById = {} } = {},
      candidateErrors,
      userErrors,
      handleCandReqInputChange,
      handleCandidateInputChange,
      handlePrivateInputChange,
      handleExperienceInputChange,
      handleUserInputChange,
      match: { params: { id: candidateId } = {} } = {},
      privateCandidateProfiles: { byId: privateCandidateProfilesById = {} } = {},
      saving,
      users: { byId: usersById = {} } = {},
      workHistories: { byId: workHistoriesById = {} } = {},
    } = this.props;

    const summarySizeMeasuredHeight = this.summarySizeMeasurementRef?.current?.clientHeight;
    const summarySizeNote =
      summarySizeMeasuredHeight > SUMMARY_SIZE_LONGEST
        ? 'Long'
        : summarySizeMeasuredHeight < SUMMARY_SIZE_SHORTEST
        ? 'Short'
        : 'Good';

    const summarySizeColor = summarySizeColors[summarySizeNote];

    const { [candidateRequirementIds[0]]: { attributes: candReq = {} } = {} } =
      candidateRequirementsById || {};

    const savingContent = saving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Saved</div>
    );

    const saveContent = saving === undefined ? null : savingContent;

    const { attributes: { email = '' } = {} } = usersById[userIds[0]] || {};

    const privateCandidateProfileId = Object.keys(privateCandidateProfilesById).find(
      (id) => privateCandidateProfilesById[id].attributes.candidateId === candidateId
    );

    const { attributes: { phoneNumber = '' } = {} } =
      privateCandidateProfilesById[privateCandidateProfileId] || {};

    const salesExpYears =
      salesExperienceMonths || salesExperienceMonths === 0
        ? Math.floor(salesExperienceMonths / 12)
        : null;
    const salesExpMonths =
      salesExperienceMonths || salesExperienceMonths === 0 ? salesExperienceMonths % 12 : null;

    const closingExpYears =
      closingExperienceMonths || closingExperienceMonths === 0
        ? Math.floor(closingExperienceMonths / 12)
        : null;
    const closingExpMonths =
      closingExperienceMonths || closingExperienceMonths === 0
        ? closingExperienceMonths % 12
        : null;

    const { attributes: latestWorkHistory = {} } = workHistoriesById[workHistoryIds[0]] || {};

    const { role: recentRole = null } = latestWorkHistory || {};

    const isCSM = recentRole === 'Account Management (CSM/AM)';
    const isNonSales = ['Non-Sales', 'B2C / Retail'].includes(recentRole);

    const { resumeTypeError, resumeSaving } = this.state;

    const { firstNameErrors, lastNameErrors } = candidateErrors;

    const { emailErrors } = userErrors;

    const commonBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      addPadding: true,
      className: styles.firstBlock,
    };

    const titleBlockProps = {
      ...commonBlockProps,
      largeTitleFont: false,
      className: styles.titleBlock,
    };

    const resumeUploadBlockProps = {
      ...commonBlockProps,
      className: null,
    };

    const quickEditorBlockProps = {
      ...commonBlockProps,
      title: 'Candidate Quick Editor',
    };

    const socialMediaBlockProps = {
      ...commonBlockProps,
      title: 'Social Media Accounts',
    };

    const personalDetailsBlockProps = {
      ...commonBlockProps,
      title: 'Personal Details',
    };

    const resumeUploadButtonProps = {
      primary: true,
      className: styles.resumeUploadButton,
      disabled,
      fakeButton: true,
    };
    const resumeUploadNewButtonProps = {
      tertiary: true,
      className: styles.resumeUploadNewButton,
      disabled,
      fakeButton: true,
    };

    const commonUserInputProps = {
      handleInputChange: handleUserInputChange,
      type: 'text',
      size: 'xxlarge',
      required: true,
    };

    const commonCandidateInputProps = {
      handleInputChange: handleCandidateInputChange,
      type: 'text',
      size: 'xxlarge',
    };

    const firstNameInputProps = {
      ...commonCandidateInputProps,
      required: true,
      name: 'firstName',
      value: firstName || '',
      label: 'First Name',
      size: 'full',
      placeholder: '',
      errors: firstName !== null ? firstNameErrors : {},
    };

    const lastNameInputProps = {
      ...commonCandidateInputProps,
      required: true,
      name: 'lastName',
      value: lastName || '',
      label: 'Last Name',
      size: 'full',
      placeholder: '',
      errors: lastName !== null ? lastNameErrors : {},
    };

    const locationInputProps = {
      value: location || '',
      name: 'location',
      placeholder: '',
      handleSelect: (place) =>
        handleCandidateInputChange({
          target: {
            value: place,
            name: 'location',
          },
        }),
    };

    const emailInputProps = {
      ...commonUserInputProps,
      size: 'full',
      name: 'email',
      value: email || '',
      label: 'Email',
      placeholder: '',
      errors: email !== null ? emailErrors : {},
    };

    const phoneInputProps = {
      ...commonCandidateInputProps,
      name: 'phoneNumber',
      value: phoneNumber || '',
      label: 'Phone #',
      placeholder: 'Area code first',
      type: 'tel',
      size: 'phone',
      handleInputChange: handlePrivateInputChange,
      disabled,
    };

    const summaryInputProps = {
      value: summary,
      name: 'summary',
      handleInputChange: handleCandidateInputChange,
      placeholder: 'We suggest keeping this to 2-3 sentences',
    };

    const validYearExp = (number) => (number !== null && number !== '') || number === 0;

    const salesExpLabel = isCSM
      ? 'How much customer facing experience do you have?'
      : 'How long have you been in sales?';

    const salesExpYearsInputProps = {
      handleInputChange: (event) =>
        handleExperienceInputChange({
          ...event,
          target: {
            months: salesExpMonths,
            years: event.target.value,
            name: 'salesExperienceMonths',
          },
        }),
      name: 'salesExperienceYears',
      value: validYearExp(salesExpYears) ? salesExpYears : '',
      placeholder: '',
      size: 'full',
      type: 'number',
      min: '0',
      max: '99',
      className: styles.inputContainer,
    };

    const salesExpMonthsInputProps = {
      handleInputChange: (event) =>
        handleExperienceInputChange({
          ...event,
          target: {
            years: salesExpYears,
            months: event.target.value,
            name: 'salesExperienceMonths',
          },
        }),
      name: 'salesExperienceMonths',
      value: validYearExp(salesExpMonths) ? salesExpMonths : '',
      placeholder: '',
      size: 'full',
      type: 'number',
      min: '0',
      max: '11',
      className: styles.inputContainer,
    };

    const closingExpLabel = 'How much closing experience do you have?';

    const closingExpYearsInputProps = {
      handleInputChange: (event) =>
        handleExperienceInputChange({
          ...event,
          target: {
            years: event.target.value,
            months: closingExpMonths,
            name: 'closingExperienceMonths',
          },
        }),
      name: 'closingExperienceYears',
      value: validYearExp(closingExpYears) ? closingExpYears : '',
      placeholder: '',
      size: 'full',
      type: 'number',
      min: '0',
      max: '99',
      className: styles.inputContainer,
    };

    const closingExpMonthsInputProps = {
      handleInputChange: (event) =>
        handleExperienceInputChange({
          ...event,
          target: {
            months: event.target.value,
            years: closingExpYears,
            name: 'closingExperienceMonths',
          },
        }),
      name: 'closingExperienceMonths',
      value: validYearExp(closingExpMonths) ? closingExpMonths : '',
      placeholder: '',
      size: 'full',
      type: 'number',
      min: '0',
      max: '11',
      className: styles.inputContainer,
    };

    const closingExpInputs =
      isCSM || isNonSales ? null : (
        <Fragment>
          <Label key="closingLabel" className={styles.label}>
            {closingExpLabel}
          </Label>
          <div key="closingInput" className={styles.yearMonthContainer}>
            <div className={styles.yearContainer}>
              <Input {...closingExpYearsInputProps} />
              <Label>years</Label>
            </div>
            <div className={styles.yearContainer}>
              <Input {...closingExpMonthsInputProps} />
              <Label>months</Label>
            </div>
          </div>
        </Fragment>
      );

    const salesExpInputs = isNonSales ? null : (
      <Fragment>
        <Label className={styles.label}>{salesExpLabel}</Label>
        <div className={styles.yearMonthContainer}>
          <div className={styles.yearContainer}>
            <Input {...salesExpYearsInputProps} />
            <Label>years</Label>
          </div>
          <div className={styles.yearContainer}>
            <Input {...salesExpMonthsInputProps} />
            <Label>months</Label>
          </div>
        </div>
      </Fragment>
    );

    const twitterInputProps = {
      ...commonCandidateInputProps,
      name: 'socialMedia,twitter,username',
      value: twitterUsername,
      label: 'Twitter Username',
      autoCapitalize: 'none',
      placeholder: '',
      size: 'xlarge',
    };

    const linkedinInputProps = {
      ...commonCandidateInputProps,
      type: 'url',
      name: 'socialMedia,linkedin,url',
      value: linkedinUrl,
      label: 'LinkedIn',
      placeholder: 'https://www.linkedin.com/in/username',
      size: 'xlarge',
    };

    const resumeInputFileProps = {
      placeholder: '',
      className: styles.candidateResumeInput,
      containerClassName: styles.candidateResumeInputContainer,
      onChange: this.handleResumeUploadButtonClick,
      isButton: true,
      name: 'resume',
      disabled,
      accept: 'application/pdf',
    };

    const resumeNameIdxStart = resume.search(/\/original\//) + 10;
    const resumeNameIdxEnd = resume.search(/pdf\?/) + 3;
    const resumePathToParse = resume.substring(resumeNameIdxStart, resumeNameIdxEnd);
    const resumeName = resumePathToParse.split('/')[1] || `${lastName}_resume.pdf`;

    const resumeContent =
      resume !== '' && resume !== '/resumes/original/missing.png' ? (
        <div className={styles.resumePresentDetails}>
          <div className={styles.resumeLabel}>
            Linked Resume
            <div className={styles.errorText}>{resumeTypeError}</div>
          </div>
          <div className={styles.resumeName}>{resumeName}</div>
          <InputFile {...resumeInputFileProps}>
            <Button {...resumeUploadNewButtonProps}>Upload New</Button>
          </InputFile>
        </div>
      ) : (
        <div className={styles.resumeDetails}>
          Don&apos;t forget to upload your resume. Employers like to see how you talk about
          yourself.
          <div className={styles.errorText}>{resumeTypeError}</div>
          <InputFile {...resumeInputFileProps}>
            <Button {...resumeUploadButtonProps}>Upload Resume</Button>
          </InputFile>
        </div>
      );

    const resumeContentFinal = resumeSaving ? (
      <div className={styles.resumeSaving}>
        <TableOverlay table="resume" />
      </div>
    ) : (
      resumeContent
    );

    const imageUploaderProps = {
      candidateId,
      disabled,
      candidateImageUploadStarted: ({ picture }) =>
        patchUserResource({
          attributes: {
            picture: {
              file_data: picture,
              large: picture,
              medium: picture,
              original: picture,
              small: picture,
              thumb: picture,
              xlarge: picture,
              xxlarge: picture,
            },
          },
          id: candidateId,
          slice: 'toolboxCandidates',
          type: 'candidates',
        }),
      pictureUrl,
    };

    const genderInputProps = {
      ...commonCandidateInputProps,
      name: 'gender',
      label: 'What do you identify as (optional)',
      value: gender || '',
      source: GENDER_SOURCE,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      defaultLabel: 'Select',
      bigInput: true,
      noMargin: true,
      disabled,
    };

    const militaryStatusInputProps = {
      ...commonCandidateInputProps,
      name: 'militaryStatus',
      label: 'Military Status (optional)',
      value: militaryStatus || '',
      source: MILITARY_STATUS_SOURCE,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      defaultLabel: 'Select',
      bigInput: true,
      disabled,
    };

    const content = (
      <div className={styles.EditorPersonal}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>General Information</div>
            <div className={styles.titleRightSideBlock}>
              <div className={styles.saveContent}>{saveContent}</div>
              <div>
                <BullhornPushButton candidateId={candidateId} />
              </div>
            </div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <Block {...resumeUploadBlockProps}>{resumeContentFinal}</Block>

          <Block {...quickEditorBlockProps}>
            <QuickEditor
              candidate={candidate}
              candReq={candReq}
              handleCandReqInputChange={handleCandReqInputChange}
              handleCandidateInputChange={handleCandidateInputChange}
            />
          </Block>

          <Block {...commonBlockProps}>
            <CandidateImageUploader {...imageUploaderProps} />
            <div className={styles.nameInputs}>
              <Input {...firstNameInputProps} />
              <Input {...lastNameInputProps} />
            </div>
            <div className={styles.genInfoInputs}>
              <div className={styles.phoneEmailInputs}>
                <Input {...emailInputProps} />
                <Input {...phoneInputProps} />
              </div>
              <Label standalone className={styles.label}>
                Location
              </Label>
              <GooglePlacesAutocomplete {...locationInputProps} />
              <Label className={styles.summaryLabel}>Professional Summary</Label>
              <Content className={styles.summarySublabel}>
                Give us a brief overview of your work background, something similar to the summary
                on a resume. Make sure this looks good as this is one of the first things that
                employers will see about you.
              </Content>
              <div className={styles.summaryContainer}>
                <div className={styles.summaryLengthNote} style={{ color: summarySizeColor }}>
                  {`Summary Length: ${summarySizeNote}`}
                </div>
                <InputRichText {...summaryInputProps} />
              </div>
              <div className={styles.summarySizeMeasurement} ref={this.summarySizeMeasurementRef}>
                <h2>Executive summary</h2>
                <ReactMarkdown>{summary}</ReactMarkdown>
              </div>

              {salesExpInputs}

              {closingExpInputs}
            </div>
          </Block>

          <Block {...personalDetailsBlockProps}>
            <InputSelect {...genderInputProps} />
            <InputSelect {...militaryStatusInputProps} />
          </Block>

          <Block {...socialMediaBlockProps}>
            <div className={styles.socialMediaInputs}>
              <div className={styles.permPlaceholder}>
                <i>@</i>
                <Input {...twitterInputProps} />
              </div>
              <Input {...linkedinInputProps} />
            </div>
          </Block>
        </div>
      </div>
    );

    const layoutWithoutSidebarProps = {
      content,
    };

    return <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />;
  }
}

export default CandidateEditorPersonal;
