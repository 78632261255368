import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { createCandidate, createUser } from 'reduxStore/schema';

import Button from 'components/Button';
import Content from 'components/Content';
import CandidateData from 'components/CandidateData';
import FormattedDate from 'components/FormattedDate';
import FormattedName from 'components/FormattedName';
import InfiniteScrollLoader from 'components/InfiniteScrollLoader';
import Table from 'components/Table';
import TableCell from 'components/Table/components/TableCell';
import TableRow from 'components/Table/components/TableRow';
import DialogueModal from 'components/DialogueModal';
import InputCheckbox from 'components/InputCheckbox';
import InputSelect from 'components/InputSelect';

import { postCandidates } from 'api/apiUsers';

import { handleError } from 'utils/common';
import { getLifecycleStatusTitle } from 'utils/constants/lifecycleStatus';

import { entityLoadStarted, patchResource } from 'shared/store/app/creators';

import { usePeriodicAction } from 'hooks/usePeriodicAction';

import ToolboxCandidatesActionDropdown from '../ToolboxCandidatesActionDropdown';

import ToolboxCandidatesSearchBar from '../ToolboxCandidatesSearchBar';

import styles from './ToolboxCandidatesTable.scss';

const ONBOARDING_STATUS_STRINGS = {
  applied: 'Applied',
  applied_forum: 'Applied via Forum',
  set_requirements: 'Set Requirements',
  created_profile: 'Created Profile',
  added_sales_info: 'Completed Registration',
  added_video: 'Completed Registration (+vid)',
  skipped_video: 'Completed Registration',
  bullhorn_import: 'Bullhorn Import',
};

const RESUME_PROCESSING_TIMEOUT_STRINGS = [
  {
    timeout: 4000,
    data: 'Pushing Resume...',
  },
  {
    timeout: 4000,
    data: 'Linking to Bullhorn...',
  },
  {
    timeout: 4000,
    data: 'Creating Public Links...',
  },
];

const onboardingErrors = [
  'APIExceptions::CandidateError::ResumeParseError',
  'APIExceptions::OnboardingError',
];

const bulkActionsSource = [
  {
    label: 'Approve',
    value: 'approved',
  },
  {
    label: 'Inbound',
    value: 'inbound',
  },
  {
    label: 'Enrichment Required',
    value: 'enrichment_required',
  },
  {
    label: 'Enrichment Complete',
    value: 'enrichment_complete',
  },
  {
    label: 'Enrichment Error',
    value: 'enrichment_error',
  },
  {
    label: 'Ready to Host',
    value: 'ready_to_host',
  },
  {
    label: 'Cancel Enrichment',
    value: null,
  },
];

const ToolboxCandidatesTable = ({
  actions,
  actions: { resourceUpdate, showModal },
  candidates: { allIds = [], byId = {} } = {},
  handleSearchInputChange,
  handleSearchFilterChange,
  handleSortChange,
  isLoading,
  loadMore,
  rainmakersAdmins: { byId: rainmakersAdminsById = {} } = {},
  userEmail,
  userUtmSource,
  fullName,
  rainmakersAdminId,
  lifecycleStatus,
  applicationStatus,
  sortBy,
  total,
  users: { byId: users = {} } = {},
}) => {
  const resumeUploadRef = useRef();
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showProgressSequence, setShowProgressSequence] = useState(false);
  const [progressModalMessage, setProgressModalMessage] = useState('');
  const [showProgressModalCloseBtn, setShowProgressModalCloseBtn] = useState(false);
  const newCandidateId = useRef();

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState();

  const runSelectedBulkAction = useCallback(
    (action) => {
      console.log(`running bulk action ${action} for candidates ${selectedCandidates.join(', ')}`);

      const attribute = action === 'approved' ? 'applicationStatus' : 'lifecycleStatus';

      // eslint-disable-next-line no-unused-vars
      for (const id of selectedCandidates) {
        dispatch(
          patchResource({
            type: 'candidates',
            slice: 'toolboxCandidates',
            attributes: {
              [attribute]: action,
            },
            id,
          })
        );
      }

      setSelectedCandidates([]);
      setSelectedBulkAction(null);
    },
    [dispatch, selectedCandidates]
  );

  const tableHeader = (currentStatus) => {
    switch (currentStatus) {
      default:
        return [
          {
            content: ' ',
            headerClassName: styles.checkboxHeader,
          },
          {
            content: 'Name',
            headerClassName: styles.nameHeader,
            value: 'full_name',
            onClick: handleSortChange,
          },
          {
            content: 'Approval & Lifecycle',
            headerClassName: styles.statusHeader,
          },
          {
            content: 'Onboarding Status',
            headerClassName: styles.onboardingStatusHeader,
            value: 'created_at',
            onClick: handleSortChange,
          },
          {
            content: 'Concierge',
            headerClassName: styles.conciergeStatusHeader,
          },
          {
            content: '',
            headerClassName: styles.candActionHeader,
          },
        ];
    }
  };

  const dispatch = useDispatch();

  const updateCandidateList = useCallback(() => {
    dispatch(
      entityLoadStarted({
        type: 'candidates',
        slice: 'toolboxCandidates',
        params: {
          page: 1,
          includeSet: 'admin_search',
          sortBy: 'created_at_desc',
          applicationStatus: ['approved', 'pending'],
          normalizeIt: true,
        },
      })
    );
  }, [dispatch]);

  const openProfileProviewModal = useCallback(
    (id) =>
      showModal({
        key: 'CandidateEditorModal',
        parent: 'ToolboxCandidatesPage',
        route: `/toolbox/candidate/${id}/edit-preview`,
      }),
    [showModal]
  );

  const openProfilePersonalModal = useCallback(
    (id) =>
      showModal({
        key: 'CandidateEditorModal',
        parent: 'ToolboxCandidatesPage',
        route: `/toolbox/candidate/${id}/edit-personal`,
      }),
    [showModal]
  );

  const resumeProcessingFn = useCallback(
    (step, text) => {
      if (step >= RESUME_PROCESSING_TIMEOUT_STRINGS.length) {
        setShowProgressModal(false);
        setShowProgressSequence(false);
        updateCandidateList();
        openProfilePersonalModal(newCandidateId.current);

        return false; // stop processing
      }

      setProgressModalMessage(text);

      return true;
    },
    [openProfilePersonalModal, updateCandidateList]
  );

  usePeriodicAction(RESUME_PROCESSING_TIMEOUT_STRINGS, resumeProcessingFn, showProgressSequence);

  const makeCells = (id) => {
    const {
      [id]: candidate,
      [id]: {
        attributes: {
          applicationStatus,
          applicationStatusUpdatedAt,
          createdAt,
          creatorType,
          firstName,
          lastName,
          onboardingStatus,
          lifecycleStatus: candidateLifecycleStatus,
          rainmakersAdminId: attrAdminId,
        } = {},
        relationships: {
          user: { data: userIds = [] } = {},
          rainmakersAdmin: { data: rainmakersAdminIds = [] } = {},
        } = {},
      } = {},
    } = byId || {};

    const rainmakersAdminId = attrAdminId || rainmakersAdminIds[0];

    const { attributes: { confirmedAt } = {} } = users[userIds[0]] || {};

    const {
      [rainmakersAdminId]: {
        attributes: { firstName: adminFirstName, lastName: adminLastName } = {},
      } = {},
    } = rainmakersAdminsById || {};

    const isArchived = applicationStatus === 'archived';
    const isApproved = applicationStatus === 'approved';
    const isPending = applicationStatus === 'pending';

    const statusClassnames = classnames({
      [styles.approvedStatus]: isApproved,
      [styles.pendingStatus]: isPending,
      [styles.archivedStatus]: isArchived,
    });

    const profileUpdatedContent = applicationStatusUpdatedAt ? (
      <FormattedDate parse date={applicationStatusUpdatedAt} format="MMM D YYYY" />
    ) : null;

    const statusContent = (
      <div className={statusClassnames}>
        <div>{applicationStatus}</div>
        <Content className={styles.date}>
          {isPending ? getLifecycleStatusTitle(candidateLifecycleStatus) : profileUpdatedContent}
        </Content>
      </div>
    );

    const candidateDataContent = (
      <div className={styles.candidateContent}>
        <CandidateData onClick={() => openProfileProviewModal(id)} candidate={candidate} />
      </div>
    );

    const actionsProps = {
      actions,
      firstName,
      lastName,
      candidateId: id,
      userId: userIds[0] || '',
      isConfirmed: Boolean(confirmedAt),
      candidate: candidate.attributes,
    };

    const actionsContent =
      isApproved || isPending ? (
        <div className={styles.actionsContent}>
          <ToolboxCandidatesActionDropdown {...actionsProps} />
        </div>
      ) : (
        <div className={styles.actionsContent} />
      );

    const profileCreatedContent = createdAt ? (
      <FormattedDate parse date={createdAt} format="MMM D YYYY" />
    ) : null;

    const { [onboardingStatus]: onboardingStatusString } = ONBOARDING_STATUS_STRINGS;

    const onboardingStatusContent =
      creatorType === 'RainmakersAdmin' ? (
        <div className={styles.onboardingStatus}>
          <div>Created</div>
          <Content className={styles.date}>{profileCreatedContent}</Content>
        </div>
      ) : (
        <div className={styles.onboardingStatus}>
          <div>{onboardingStatusString}</div>
          <Content className={styles.date}>{profileCreatedContent}</Content>
        </div>
      );

    const conciergeContent = adminFirstName ? (
      <div className={styles.conciergeStatus}>
        <FormattedName firstName={adminFirstName} lastName={adminLastName} />
      </div>
    ) : (
      <div className={styles.conciergeStatus}>
        <div className={styles.iconDanger}>!</div>
      </div>
    );

    const cells = [
      <TableCell key={0}>
        <InputCheckbox
          className={styles.selectCheckbox}
          checked={selectedCandidates.includes(id)}
          name="selectCandidate"
          handleInputChange={() => {
            setSelectedCandidates((data) =>
              data.includes(id) ? data.filter((item) => item !== id) : [...data, id]
            );
            setSelectedBulkAction(null);
          }}
        />
      </TableCell>,
      <TableCell key={1}>{candidateDataContent}</TableCell>,
      <TableCell key={2}>{statusContent}</TableCell>,
      <TableCell key={3}>{onboardingStatusContent}</TableCell>,
      <TableCell key={4}>{conciergeContent}</TableCell>,
      <TableCell noFlex key={6}>
        {actionsContent}
      </TableCell>,
    ];

    return cells;
  };

  const makeRows = (id) => (
    <TableRow id={id} key={id}>
      {makeCells(id)}
    </TableRow>
  );

  const tableContent = allIds
    .filter((id) => {
      const { [id]: { attributes: { temporary } = {} } = {} } = byId || {};

      return !temporary;
    })
    .map(makeRows);

  const searchBarProps = {
    fullName,
    userEmail,
    userUtmSource,
    rainmakersAdminId,
    applicationStatus,
    lifecycleStatus,
    handleSearchInputChange,
    handleSearchFilterChange,
  };

  const createCandidateClick = () => {
    const resource = createCandidate();
    const userResource = createUser();

    resourceUpdate({
      slice: 'toolboxCandidates',
      type: 'candidates',
      id: resource.id,
      resource: {
        ...resource,
        attributes: {
          ...resource.attributes,
        },
        relationships: {
          user: {
            data: [userResource.id],
          },
        },
      },
    });

    resourceUpdate({
      slice: 'toolboxCandidates',
      type: 'users',
      id: userResource.id,
      resource: {
        ...userResource,
        attributes: {
          ...userResource.attributes,
          currentProfileType: 'Candidate',
          currentProfileId: resource.id,
        },
      },
    });

    const route = `/toolbox/candidate/${resource.id}/edit-personal`;

    showModal({
      key: 'CandidateEditorModal',
      parent: 'ToolboxCandidatesPage',
      route,
    });
  };

  const onClickResume = useCallback((event) => {
    const reader = new FileReader();
    const data = event.target.files[0];

    // if (image.size > maxImageSizeMb * 1_000_000) {
    //   setImageTypeError(`File must be less than ${maxImageSizeMb} MB`);
    //   return;
    // }

    reader.onloadend = () => {
      const fileData = reader.result;

      const args = {
        data: {
          candidate: {
            resume: {
              fileData,
            },
          },
        },
        config: {
          params: {
            normalizeIt: true,
          },
        },
      };

      setProgressModalMessage('Creating Candidate...');
      setShowProgressModal(true);
      setShowProgressModalCloseBtn(false);

      postCandidates(args)
        // .then(() => {
        //   // eslint-disable-next-line no-throw-literal
        //   throw {
        //     response: {
        //       data: {
        //         errors: [
        //           {
        //             name: 'APIExceptions::OnboardingError',
        //             error: 'Cannot parse the resume',
        //           },
        //         ],
        //       },
        //     },
        //   };
        // })
        .then((response) => {
          const { data: { entities: { candidate: { allIds } = {} } = {} } = {} } = response;
          const id = allIds?.[0];

          if (id) {
            newCandidateId.current = id;

            setShowProgressSequence(true);
          }
        })
        .catch((err) => {
          const { response: { data: { errors = [] } = {} } = {} } = err;

          if (
            errors.length > 0 &&
            onboardingErrors.includes(errors[0].name) &&
            errors[0].status !== 200
          ) {
            setProgressModalMessage(errors[0].error);
          } else if (typeof errors === 'object' && errors !== null) {
            setProgressModalMessage(JSON.stringify(errors));
          } else {
            setProgressModalMessage('Error Creating Candidate');
          }
          setShowProgressModalCloseBtn(true);
          setShowProgressModal(true);
          handleError(err);
        })
        .finally(() => {
          resumeUploadRef.current.value = null;
        });
    };

    reader.readAsDataURL(data);
  }, []);

  const titleContent = (
    <div className={styles.candidateHeader}>
      <div className={styles.candidateHeaderLeft}>
        <ToolboxCandidatesSearchBar {...searchBarProps} />
      </div>
      <div className={styles.candidateHeaderRight}>
        <Button primary onClick={createCandidateClick}>
          + Candidate
        </Button>
        <div className={styles.resumeUploadArea}>
          <label htmlFor="resumeUpload" className={styles.resumeUploadBtn}>
            + Resume
          </label>
          <input
            type="file"
            ref={resumeUploadRef}
            name="resume"
            onChange={onClickResume}
            id="resumeUpload"
          />
        </div>
        <InputSelect
          className={styles.bulkActionsSelect}
          fullWidth
          source={bulkActionsSource}
          defaultLabel="Bulk Actions"
          value={selectedBulkAction}
          disabled={selectedCandidates.length === 0}
          handleInputChange={({ target: { value } }) => {
            setSelectedBulkAction(value);
            runSelectedBulkAction(value);
          }}
          defaultIcon="caret-down"
          activeIcon="caret-up"
        />
      </div>
    </div>
  );

  const infiniteScrollProps = {
    loadMore,
    hasMore: total > allIds.length,
    loader: isLoading ? (
      <div key="infiniteScrollLoader" />
    ) : (
      <InfiniteScrollLoader key="infiniteScrollLoader" />
    ),
    useWindow: false,
  };

  const tableProps = {
    blockProps: {
      titleClass: styles.dashboardTitleContainer,
    },
    titleContent,
    tableContent,
    header: tableHeader(applicationStatus),
    infiniteScrollProps,
    tableName: 'adminCandidates',
    sortBy,
    isEmpty: !isLoading && allIds.length === 0,
    emptyTextString: 'No Candidates',
    isLoading,
  };

  return (
    <>
      <Table {...tableProps} />
      {showProgressModal && (
        <DialogueModal className={styles.progressModalContainer}>
          <div className={styles.progressModal}>
            <div>{progressModalMessage}</div>
            {showProgressModalCloseBtn && (
              <Button
                warning
                className={styles.closeButton}
                onClick={() => setShowProgressModal(false)}
              >
                Close
              </Button>
            )}
          </div>
        </DialogueModal>
      )}
    </>
  );
};

export default ToolboxCandidatesTable;
