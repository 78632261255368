import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import cn from 'classnames';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import { handleError } from 'utils/common';
import { candidateInputChange } from 'utils/formHelpers/CandidateHelpers';

import { MILITARY_STATUS_SOURCE, GENDER_SOURCE } from 'connectors/Defaults/constants';

import Block from 'components/Block';
import Button from 'components/Button';
import CandidateImageUploader from 'components/CandidateImageUploader';
import Content from 'components/Content';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import Input from 'components/Input';
import InputRichText from 'components/InputRichText';
import InputSelect from 'components/InputSelect';
import InputFile from 'components/InputFile';
import Label from 'components/Label';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';

import { promiseEmailValid } from '../promises';

import duck from '../redux';

import EmailChangeModal from './components/EmailChangeModal';

import styles from './EditPersonal.scss';

@CandidateAuthHOC()
class EditPersonal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeTypeError: null,
      showEmailChangeModal: false,
      emailError: null,
    };
  }

  componentDidMount() {
    const { candidate: { id: candidateId } = {} } = this.props;

    promiseGetFullCandidate({ candidateId }).then(this.onCandidateSuccess).catch(handleError);
  }

  onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = this.props;

    candidateProfilePatchDone({ candidateProfile });
  };

  updateEmailStart = ({ password, newEmail }) => {
    const {
      actions: { emailChangeStarted },
    } = this.props;

    this.setState({ emailError: null });

    promiseEmailValid({ email: newEmail }).then((response) => {
      if (response.email) {
        this.setState({ emailError: 'Email has been taken' });
      } else {
        emailChangeStarted({ password, newEmail });
        this.setState({ showEmailChangeModal: false });
      }
    });
  };

  handleResumeUploadButtonClick = (event) => {
    event.preventDefault();
    const {
      actions: { candidateFileUploadStarted },
    } = this.props;

    this.setState({
      resumeTypeError: null,
    });

    const resume = event.target.files[0];

    if (resume.size > 10000000) {
      return this.setState({ resumeTypeError: 'File size must be less than 10 MB' });
    }

    if (resume.type === 'application/pdf') {
      candidateFileUploadStarted({ file: resume, type: 'resume' });
    } else {
      this.setState({ resumeTypeError: 'Resume must be a PDF' });
    }
  };

  handleCandidateTextInputChange = (event) => {
    const {
      actions: { candidatePatchStarted },
      candidate,
    } = this.props;

    const patchArgs = candidateInputChange(event, candidate);

    candidatePatchStarted(patchArgs);
  };

  handleExperienceInputChange = (event) => {
    const {
      target: { years, months, name },
    } = event;

    const {
      actions: { candidatePatchStarted },
    } = this.props;

    const realYears = years === '' || years === null ? 0 : parseInt(years, 10);
    const realMonths = months === '' || months === null ? 0 : parseInt(months, 10);

    const expInMonths = realYears * 12 + realMonths;

    return candidatePatchStarted({ name, newValue: expInMonths });
  };

  handlePrivateInputChange = (event) => {
    const {
      target: { type, name, value, checked },
    } = event;
    const newValue = type === 'checkbox' ? checked : value;

    const {
      actions: { candidatePatchStarted },
    } = this.props;

    return candidatePatchStarted({ name, newValue, privateInfo: true });
  };

  render() {
    const {
      candidateLoading,
      resumeSaving,
      candidateSaving,
      candidateErrors,
      candidate,
      candidate: {
        closingExperienceMonths: closingExperience,
        firstName,
        gender,
        id: candidateId,
        lastName,
        location = '',
        militaryStatus,
        picture: { xxlarge: pictureUrl = '' } = {},
        privateCandidateProfile: { phoneNumber = '' } = {},
        resume = '',
        salesExperienceMonths: salesExperience,
        socialMedia: {
          linkedin: { url: linkedinUrl = '' } = {},
          twitter: { username: twitterUsername = '' } = {},
        } = {},
        summary,
        workHistories: workHistories = [],
      } = {},
      user: { email } = {},
      actions: { candidateImageUploadStarted },
      candidateSaveError,
      isProfileLocked,
      location: { search } = {},
    } = this.props;

    const { resumeTypeError, showEmailChangeModal, emailError } = this.state;

    const { firstName: firstNameErrors = {}, lastName: lastNameErrors = {} } =
      candidateErrors || {};

    const { model = null, 'fields[]': fields = [] } = queryString.parse(search);

    const highlightAllBlocks = model === 'personal_info' && fields.length === 0;

    let autofocusFields = fields;

    if (fields && typeof fields === 'string') {
      autofocusFields = [fields];
    }

    console.log('autofocusFields', autofocusFields);

    const salesExpYears =
      salesExperience || salesExperience === 0 ? Math.floor(salesExperience / 12) : null;
    const salesExpMonths = salesExperience || salesExperience === 0 ? salesExperience % 12 : null;

    const closingExpYears =
      closingExperience || closingExperience === 0 ? Math.floor(closingExperience / 12) : null;
    const closingExpMonths =
      closingExperience || closingExperience === 0 ? closingExperience % 12 : null;

    const { role: recentRole = null } = workHistories[0] || {};

    const isCSM = recentRole === 'Account Management (CSM/AM)';
    const isNonSales = ['Non-Sales', 'B2C / Retail'].includes(recentRole);

    const savingContent = candidateSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const statusContent = isProfileLocked ? (
      <div className={styles.profileLocked}>Profile Locked</div>
    ) : null;

    const saveContent = candidateSaving === undefined ? null : statusContent || savingContent;

    const commonBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      largeTitleFont: true,
      addPadding: true,
      className: cn(styles.firstBlock, highlightAllBlocks && styles.highlight),
    };

    const titleBlockProps = {
      ...commonBlockProps,
      largeTitleFont: false,
      className: styles.titleBlock,
    };

    const resumeUploadBlockProps = {
      ...commonBlockProps,
      className: autofocusFields.includes('resume') ? styles.highlight : null,
    };

    const socialMediaBlockProps = {
      ...commonBlockProps,
      title: 'Social Media Accounts',
    };

    const personalDetailsBlockProps = {
      ...commonBlockProps,
      title: 'Personal Details',
    };

    const resumeUploadButtonProps = {
      primary: true,
      className: styles.resumeUploadButton,
      disabled: !candidateId || isProfileLocked,
      fakeButton: true,
      highlight: autofocusFields.includes('resume'),
    };

    const resumeUploadNewButtonProps = {
      tertiary: true,
      className: styles.resumeUploadNewButton,
      disabled: !candidateId || isProfileLocked,
      fakeButton: true,
    };

    const commonCandidateInputProps = {
      handleInputChange: this.handleCandidateTextInputChange,
      type: 'text',
      size: 'xxlarge',
    };

    const firstNameInputProps = {
      ...commonCandidateInputProps,
      required: true,
      name: 'firstName',
      value: firstName || '',
      label: 'First Name',
      size: 'full',
      placeholder: '',
      autoFocus: autofocusFields.includes('first_name'),
      highlight: autofocusFields.includes('first_name'),
      disabled: isProfileLocked,
      errors: firstName || firstName === '' ? firstNameErrors : {},
    };

    const lastNameInputProps = {
      ...commonCandidateInputProps,
      required: true,
      name: 'lastName',
      value: lastName || '',
      label: 'Last Name',
      size: 'full',
      placeholder: '',
      autoFocus: autofocusFields.includes('last_name'),
      highlight: autofocusFields.includes('last_name'),
      disabled: isProfileLocked,
      errors: lastName || lastName === '' ? lastNameErrors : {},
    };

    const locationInputProps = {
      disabled: isProfileLocked,
      name: 'location',
      value: location || '',
      autoFocus: autofocusFields.includes('location'),
      highlight: autofocusFields.includes('location'),
      handleSelect: (place) =>
        this.handleCandidateTextInputChange({
          target: {
            value: place,
            name: 'location',
          },
        }),
    };

    const phoneInputProps = {
      ...commonCandidateInputProps,
      name: 'phoneNumber',
      value: phoneNumber || '',
      label: 'Phone #',
      placeholder: 'Area code first',
      type: 'tel',
      size: 'phone',
      autoFocus: autofocusFields.includes('phone'),
      highlight: autofocusFields.includes('phone'),
      disabled: isProfileLocked,
      handleInputChange: this.handlePrivateInputChange,
    };

    const cleanSummary = summary ? summary.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '') : '';

    const summaryInputProps = {
      disabled: isProfileLocked,
      readOnly: isProfileLocked,
      value: summary,
      name: 'summary',
      autoFocus: autofocusFields.includes('summary') && (cleanSummary === '' || !cleanSummary),
      highlight: autofocusFields.includes('summary') && (cleanSummary === '' || !cleanSummary),
      handleInputChange: this.handleCandidateTextInputChange,
      placeholder: 'We suggest keeping this to 2-3 sentences',
    };

    const validYearExp = (number) => (number !== null && number !== '') || number === 0;

    const salesExpLabel = isCSM
      ? 'How much customer facing experience do you have?'
      : 'How long have you been in sales?';

    const salesExpYearsInputProps = {
      handleInputChange: (event) =>
        this.handleExperienceInputChange({
          ...event,
          target: {
            months: salesExpMonths,
            years: event.target.value,
            name: 'salesExperienceMonths',
          },
        }),
      name: 'salesExperienceYears',
      value: validYearExp(salesExpYears) ? salesExpYears : '',
      placeholder: '',
      size: 'full',
      type: 'text',
      min: '0',
      max: '99',
      className: styles.inputContainer,
      disabled: isProfileLocked,
      autoFocus:
        autofocusFields.includes('sales_experience_months') &&
        (salesExperience === '' || !salesExperience),
      highlight:
        autofocusFields.includes('sales_experience_months') &&
        (salesExperience === '' || !salesExperience),
    };

    const salesExpMonthsInputProps = {
      handleInputChange: (event) =>
        this.handleExperienceInputChange({
          ...event,
          target: {
            years: salesExpYears,
            months: event.target.value,
            name: 'salesExperienceMonths',
          },
        }),
      name: 'salesExperienceMonths',
      value: validYearExp(salesExpMonths) ? salesExpMonths : '',
      placeholder: '',
      size: 'full',
      type: 'text',
      min: '0',
      max: '11',
      className: styles.inputContainer,
      disabled: isProfileLocked,
      autoFocus:
        autofocusFields.includes('sales_experience_months') &&
        (salesExperience === '' || !salesExperience),
      highlight:
        autofocusFields.includes('sales_experience_months') &&
        (salesExperience === '' || !salesExperience),
    };

    const closingExpLabel = 'How much closing experience do you have?';

    const closingExpYearsInputProps = {
      handleInputChange: (event) =>
        this.handleExperienceInputChange({
          ...event,
          target: {
            years: event.target.value,
            months: closingExpMonths,
            name: 'closingExperienceMonths',
          },
        }),
      name: 'closingExperienceYears',
      value: validYearExp(closingExpYears) ? closingExpYears : '',
      placeholder: '',
      size: 'full',
      type: 'text',
      min: '0',
      max: '99',
      className: styles.inputContainer,
      disabled: isProfileLocked,
      autoFocus:
        autofocusFields.includes('closing_experience_months') &&
        (closingExperience === '' || !closingExperience),
      highlight:
        autofocusFields.includes('closing_experience_months') &&
        (closingExperience === '' || !closingExperience),
    };

    const closingExpMonthsInputProps = {
      handleInputChange: (event) =>
        this.handleExperienceInputChange({
          ...event,
          target: {
            months: event.target.value,
            years: closingExpYears,
            name: 'closingExperienceMonths',
          },
        }),
      name: 'closingExperienceMonths',
      value: validYearExp(closingExpMonths) ? closingExpMonths : '',
      placeholder: '',
      size: 'full',
      type: 'text',
      min: '0',
      max: '11',
      className: styles.inputContainer,
      disabled: isProfileLocked,
      autoFocus:
        autofocusFields.includes('closing_experience_months') &&
        (closingExperience === '' || !closingExperience),
      highlight:
        autofocusFields.includes('closing_experience_months') &&
        (closingExperience === '' || !closingExperience),
    };

    const closingExpInputs =
      isCSM || isNonSales ? null : (
        <Fragment>
          <Label className={styles.label}>{closingExpLabel}</Label>
          <div className={styles.yearMonthContainer}>
            <div className={styles.yearContainer}>
              <Input {...closingExpYearsInputProps} />
              <Label>years</Label>
            </div>
            <div className={styles.yearContainer}>
              <Input {...closingExpMonthsInputProps} />
              <Label>months</Label>
            </div>
          </div>
        </Fragment>
      );

    const salesExpInputs = isNonSales ? null : (
      <Fragment>
        <Label className={styles.label}>{salesExpLabel}</Label>
        <div className={styles.yearMonthContainer}>
          <div className={styles.yearContainer}>
            <Input {...salesExpYearsInputProps} />
            <Label>years</Label>
          </div>
          <div className={styles.yearContainer}>
            <Input {...salesExpMonthsInputProps} />
            <Label>months</Label>
          </div>
        </div>
      </Fragment>
    );

    const twitterInputProps = {
      ...commonCandidateInputProps,
      name: 'socialMedia,twitter,username',
      value: twitterUsername,
      label: 'Twitter Username',
      autoCapitalize: 'none',
      placeholder: '',
      size: 'xlarge',
      disabled: isProfileLocked,
      autoFocus: autofocusFields.includes('social_media.twitter.username'),
      highlight: autofocusFields.includes('social_media.twitter.username'),
    };

    const linkedinInputProps = {
      ...commonCandidateInputProps,
      name: 'socialMedia,linkedin,url',
      value: linkedinUrl,
      label: 'LinkedIn',
      type: 'url',
      placeholder: 'https://www.linkedin.com/in/username',
      size: 'xlarge',
      disabled: isProfileLocked,
      autoFocus: autofocusFields.includes('social_media.linkedin.url'),
      highlight: autofocusFields.includes('social_media.linkedin.url'),
    };

    const resumeInputFileProps = {
      placeholder: '',
      className: styles.candidateResumeInput,
      containerClassName: styles.candidateResumeInputContainer,
      onChange: this.handleResumeUploadButtonClick,
      isButton: true,
      name: 'resume',
      disabled: !candidateId || isProfileLocked,
      accept: 'application/pdf',
    };

    const resumeNameIdxStart = resume.search(/\/original\//) + 10;
    const resumeNameIdxEnd = resume.search(/pdf\?/) + 3;
    const resumePathToParse = resume.substring(resumeNameIdxStart, resumeNameIdxEnd);
    const resumeName = resumePathToParse.split('/')[1] || `${lastName}_resume.pdf`;

    const resumeContent =
      resume !== '' && resume !== '/resumes/original/missing.png' ? (
        <Content className={styles.resumePresentDetails}>
          <div className={styles.resumeLabel}>
            Linked Resume
            <div className={styles.errorText}>{resumeTypeError}</div>
          </div>
          <div className={styles.resumeName}>{resumeName}</div>
          <InputFile {...resumeInputFileProps}>
            <Button {...resumeUploadNewButtonProps}>Upload New</Button>
          </InputFile>
        </Content>
      ) : (
        <Content className={styles.resumeDetails}>
          Don&apos;t forget to upload your resume. Employers like to see how you talk about
          yourself.
          <div className={styles.errorText}>{resumeTypeError}</div>
          <InputFile {...resumeInputFileProps}>
            <Button {...resumeUploadButtonProps}>Upload Resume</Button>
          </InputFile>
        </Content>
      );

    const resumeContentFinal = resumeSaving ? (
      <div className={styles.resumeSaving}>
        <TableOverlay table="resume" />
      </div>
    ) : (
      resumeContent
    );

    const emailChangeModalProps = {
      updateEmailStart: this.updateEmailStart,
      handleCloseDialogue: () => this.setState({ showEmailChangeModal: false }),
      emailError,
    };

    const emailChangeModalContent = showEmailChangeModal ? (
      <EmailChangeModal {...emailChangeModalProps} />
    ) : null;

    const updateEmailButtonProps = {
      tertiaryThin: true,
      onClick: () => this.setState({ showEmailChangeModal: true }),
      disabled: isProfileLocked,
    };

    const imageUploaderProps = {
      disabled: isProfileLocked,
      candidateId,
      pictureUrl,
      candidateImageUploadStarted,
      highlight: autofocusFields.includes('picture'),
    };

    const genderInputProps = {
      ...commonCandidateInputProps,
      name: 'gender',
      label: 'What do you identify as (optional)',
      value: gender || '',
      source: GENDER_SOURCE,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      defaultLabel: 'Select',
      bigInput: true,
      noMargin: true,
      autoFocus: autofocusFields.includes('gender'),
      highlight: autofocusFields.includes('gender'),
    };

    const militaryStatusInputProps = {
      ...commonCandidateInputProps,
      name: 'militaryStatus',
      label: 'Military Status (optional)',
      value: militaryStatus || '',
      source: MILITARY_STATUS_SOURCE,
      defaultIcon: 'caret-down',
      activeIcon: 'caret-up',
      defaultLabel: 'Select',
      bigInput: true,
      autoFocus: autofocusFields.includes('military_status'),
      highlight: autofocusFields.includes('military_status'),
    };

    const profileLockOverlay = isProfileLocked ? <TableOverlay lockedNotFixed /> : null;

    const content = (
      <div className={styles.EditorPersonal}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Personal Info</div>
            <div className={styles.saveContent}>{saveContent}</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          {profileLockOverlay}

          <Block {...resumeUploadBlockProps}>{resumeContentFinal}</Block>

          <Block {...commonBlockProps}>
            <CandidateImageUploader {...imageUploaderProps} />
            <div className={styles.emailContainer}>
              <Label standalone>Email Address</Label>
              <div className={styles.emailContent}>
                {email}
                <Button {...updateEmailButtonProps}>Update</Button>
                <div className={styles.errorText}>{candidateSaveError}</div>
              </div>
            </div>
            <div className={styles.nameInputs}>
              <Input {...firstNameInputProps} />
              <Input {...lastNameInputProps} />
              <Input {...phoneInputProps} />
            </div>
            <div className={styles.genInfoInputs}>
              <Label standalone>Location</Label>

              <GooglePlacesAutocomplete {...locationInputProps} />

              <Label className={styles.summaryLabel}>Professional Summary</Label>

              <Content className={styles.summarySublabel}>
                Give us a brief overview of your work background, something similar to the summary
                on a resume. Make sure this looks good as this is one of the first things that
                employers will see about you.
              </Content>

              <InputRichText {...summaryInputProps} />

              {salesExpInputs}

              {closingExpInputs}
            </div>
          </Block>

          <Block {...personalDetailsBlockProps}>
            <InputSelect {...genderInputProps} />
            <InputSelect {...militaryStatusInputProps} />
          </Block>

          <Block {...socialMediaBlockProps}>
            <div className={styles.socialMediaInputs}>
              <div className={styles.permPlaceholder}>
                <i>@</i>
                <Input {...twitterInputProps} />
              </div>
              <Input {...linkedinInputProps} />
            </div>
          </Block>

          {emailChangeModalContent}
        </div>
      </div>
    );

    return (
      <>
        <Helmet title="Candidate Editor" />
        <ReadyContainer className={styles.CandidateEditorPersonal}>
          <Sidebar type="candidateEditor" page="edit-personal" candidate={candidate} />
          <LayoutWithoutSidebar
            content={
              <>
                {candidateLoading && <TableOverlay />}
                {content}
              </>
            }
          />
        </ReadyContainer>
      </>
    );
  }
}

export default ConnectContainer(duck)(EditPersonal);
