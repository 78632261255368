import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useDesiredRole = () => {
  const { search } = useLocation();

  const [preselectedRoles, setPreselectedRoles] = useState({});

  const preselectedRolesStr = useMemo(
    () => `?${queryString.stringify(preselectedRoles)}`,
    [preselectedRoles]
  );

  const desiredRoles = useMemo(() => {
    const temp = preselectedRoles['desired_role[]'] || [];
    return Array.isArray(temp) ? temp : [temp];
  }, [preselectedRoles]);

  useEffect(() => {
    if (search) {
      const parsed = queryString.parse(search);

      const { recent_role } = parsed || {};
      const desired_role = parsed['desired_role[]'];

      if (desired_role || recent_role) {
        setPreselectedRoles({
          ...(desired_role ? { 'desired_role[]': desired_role } : {}),
          ...(recent_role ? { recent_role } : {}),
        });
      }
    }
  }, [search]);

  return {
    preselectedRoles,
    preselectedRolesStr,
    desiredRoles,
    recentRole: preselectedRoles.recent_role,
  };
};

export const stringify = (recentRole, desiredRoles) => {
  return queryString.stringify({
    ['desired_role[]']: desiredRoles,
    recent_role: recentRole,
  });
};

export const toolboxToPreselectedRoles = (recent_role, desired_role) => {
  return {
    ...(Array.isArray(desired_role) && desired_role.length
      ? { 'desired_role[]': desired_role }
      : {}),
    ...(recent_role ? { recent_role } : {}),
  };
};
