import React, { Fragment } from 'react';

import Label from 'components/Label';
import InputRadio from 'components/InputRadio';
import Input from 'components/Input';

import { SALES_EXPERIENCE_QUESTIONS } from '../../../constants';

import styles from './TertiaryQuestions.scss';

const TertiaryQuestions = ({
  recentRole,
  hasRecentRole,
  handleInputChange,
  handleExperienceInputChange,
  salesExperience,
  closingExperience,
  showTertiary,
  ...otherProps
}) => {
  const salesExpYears =
    salesExperience || salesExperience === 0 ? Math.floor(salesExperience / 12) : null;
  const salesExpMonths = salesExperience || salesExperience === 0 ? salesExperience % 12 : null;

  const closingExpYears =
    closingExperience || closingExperience === 0 ? Math.floor(closingExperience / 12) : null;
  const closingExpMonths =
    closingExperience || closingExperience === 0 ? closingExperience % 12 : null;

  const isCSM = recentRole === 'Account Management (CSM/AM)';
  const isNonSales = ['Non-Sales', 'B2C / Retail'].includes(recentRole);

  const questions = hasRecentRole
    ? SALES_EXPERIENCE_QUESTIONS[recentRole] || SALES_EXPERIENCE_QUESTIONS.default
    : SALES_EXPERIENCE_QUESTIONS['Non-Sales'];

  const questionsFinal = recentRole === 'recentGrad' ? null : questions;

  const questionsContent = questionsFinal
    ? questionsFinal.map(({ question, options, camelKey }) => {
        const props = {
          options,
          name: camelKey,
          value: otherProps[camelKey],
          handleInputChange,
        };

        return [
          <Label key={question} required className={styles.label}>
            {question}
          </Label>,
          <InputRadio key={camelKey} {...props} />,
        ];
      })
    : null;

  const validYearExp = (number) => number !== null && number !== '';

  const salesExpLabel = isCSM
    ? 'How much customer facing experience do you have?'
    : 'How long have you been in sales?';

  const salesExpYearsInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          months: salesExpMonths,
          years: event.target.value,
          name: 'salesExperience',
        },
      }),
    name: 'salesExperienceYears',
    value: validYearExp(salesExpYears) || salesExpYears === 0 ? parseInt(salesExpYears, 10) : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '99',
    className: styles.inputContainer,
  };

  const salesExpMonthsInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          years: salesExpYears,
          months: event.target.value,
          name: 'salesExperience',
        },
      }),
    name: 'salesExperienceMonths',
    value: validYearExp(salesExpMonths) || salesExpMonths === 0 ? parseInt(salesExpMonths, 10) : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '11',
    className: styles.inputContainer,
  };

  const closingExpLabel = 'How much closing experience do you have?';

  const closingExpYearsInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          years: event.target.value,
          months: closingExpMonths,
          name: 'closingExperience',
        },
      }),
    name: 'closingExperienceYears',
    value:
      validYearExp(closingExpYears) || closingExpYears === 0 ? parseInt(closingExpYears, 10) : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '99',
    className: styles.inputContainer,
  };

  const closingExpMonthsInputProps = {
    handleInputChange: (event) =>
      handleExperienceInputChange({
        ...event,
        target: {
          months: event.target.value,
          years: closingExpYears,
          name: 'closingExperience',
        },
      }),
    name: 'closingExperienceMonths',
    value:
      validYearExp(closingExpMonths) || closingExpMonths === 0
        ? parseInt(closingExpMonths, 10)
        : '',
    placeholder: '',
    size: 'full',
    type: 'text',
    min: '0',
    max: '11',
    className: styles.inputContainer,
  };

  const closingExpInputs =
    isCSM || isNonSales || recentRole === 'recentGrad' ? null : (
      <Fragment>
        <Label required className={styles.label}>
          {closingExpLabel}
        </Label>
        <div key="closingInput" className={styles.yearMonthContainer}>
          <div className={styles.yearContainer}>
            <Input {...closingExpYearsInputProps} />
            <Label>years</Label>
          </div>
          <div className={styles.yearContainer}>
            <Input {...closingExpMonthsInputProps} />
            <Label>months</Label>
          </div>
        </div>
      </Fragment>
    );

  const salesExpInputs =
    isNonSales || recentRole === 'recentGrad' ? null : (
      <Fragment>
        <Label required className={styles.label}>
          {salesExpLabel}
        </Label>
        <div className={styles.yearMonthContainer}>
          <div className={styles.yearContainer}>
            <Input {...salesExpYearsInputProps} />
            <Label>years</Label>
          </div>
          <div className={styles.yearContainer}>
            <Input {...salesExpMonthsInputProps} />
            <Label>months</Label>
          </div>
        </div>
      </Fragment>
    );

  return showTertiary ? (
    <div className={styles.tertiaryContainer} data-testid="TertiaryQuestions">
      <div className={styles.containerTitle}>SALES EXPERIENCE</div>

      {questionsContent}

      {salesExpInputs}

      {closingExpInputs}
    </div>
  ) : null;
};

export default TertiaryQuestions;
