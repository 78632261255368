import React, { useState, useEffect, useCallback } from 'react';
import trimEnd from 'lodash.trimend';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

import styles from './InputRichText.scss';

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: styles.editorButton },
    { label: 'Italic', style: 'ITALIC', className: styles.editorButton },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item', className: styles.editorButton },
    { label: 'OL', style: 'ordered-list-item', className: styles.editorButton },
  ],
};

const InputRichText = (props) => {
  const [state, setState] = useState({
    value: null,
    RichTextEditor: null,
  });

  useEffect(() => {
    const RichTextEditor = require('react-rte').default;

    const { value } = props;

    const valueInit = value
      ? RichTextEditor.createValueFromString(value, 'markdown')
      : RichTextEditor.createEmptyValue();

    setState((prevState) => ({
      ...prevState,
      RichTextEditor,
      value: valueInit,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    debounce(({ value }) => {
      const { name, handleInputChange } = props;

      const newValue = value.toString('markdown');
      const cleanString = newValue ? newValue.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '') : '';

      const noTrailingLines = trimEnd(cleanString, '\r\n');

      handleInputChange({
        target: {
          name,
          value: cleanString ? noTrailingLines : null,
          type: 'text',
        },
      });
    }, props.debounceTime ?? 200),
    []
  );

  const {
    disabled,
    readOnly,
    placeholder,
    autoFocus,
    highlight,
    containerClassName,
    rteClassName,
    editorClassName,
  } = props;

  const { value, RichTextEditor } = state;

  return (
    <div className={classnames(styles.container, containerClassName)}>
      {RichTextEditor && (
        <RichTextEditor
          autoFocus={autoFocus}
          className={classnames(rteClassName, styles.editor, { [styles.focus]: autoFocus })}
          editorClassName={editorClassName}
          disabled={disabled}
          highlight={highlight}
          toolbarConfig={toolbarConfig}
          value={value}
          onChange={(value) => {
            setState((prevState) => ({ ...prevState, value }));
            onChange({ value });
          }}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default InputRichText;
