import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/Input';
import DropdownSelectMany from 'components/DropdownSelectMany';

import { entityLoadStarted } from 'shared/store/app/creators';
import { entitySelectorFactory } from 'reduxStore/selectorFactories';

import { candidateLifecycleOptions } from 'utils/constants/lifecycleStatus';

import styles from './ToolboxCandidatesSearchBar.scss';

const ToolboxCandidatesSearchBar = ({
  lifecycleStatus,
  applicationStatus,
  handleSearchInputChange,
  handleSearchFilterChange,
  fullName,
  userEmail,
  userUtmSource,
  rainmakersAdminId = [],
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      entityLoadStarted({
        type: 'rainmakers_admins',
        typeOverride: 'talent_concierges',
        slice: 'toolboxCandidates',
        params: {
          page: 1,
          includeSet: 'user',
          talentConcierge: true,
          sortBy: 'first_name_asc,last_name_asc',
        },
      })
    );
  }, [dispatch]);

  const talentConcierges = useSelector(
    entitySelectorFactory('toolboxCandidates', 'talentConcierge')
  );

  const { allIds: talentConciergesAllIds = [], byId: talentConciergesById = {} } =
    talentConcierges || {};

  const nameSearchProps = {
    autoComplete: 'off',
    name: 'fullName',
    placeholder: 'Search by candidate name',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: fullName,
  };

  const emailSearchProps = {
    autoComplete: 'off',
    name: 'userEmail',
    placeholder: 'Search by candidate email',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: userEmail,
  };

  const utmSourceSearchProps = {
    autoComplete: 'off',
    name: 'userUtmSource',
    placeholder: 'Search by UTM Source',
    size: 'full',
    handleInputChange: handleSearchInputChange,
    isSearch: true,
    value: userUtmSource,
  };

  const stateOptions = [
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
  ];

  const stateSource = stateOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedStates = applicationStatus.map((state) => ({ state, type: 'state' }));

  const stateFilterProps = {
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: stateSource,
    name: 'applicationStatus',
    key: 'applicationStatus',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'applicationStatus',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedStates,
    defaultLabel: 'Click to select application statuses',
  };

  const candidateLifecycleSource = candidateLifecycleOptions.map((state) => ({
    indent: 0,
    label: state.label,
    optionType: 'role',
    subCategories: [],
    value: {
      type: 'state',
      state: state.value,
    },
  }));

  const selectedLifecycleStatus = lifecycleStatus.map((state) => ({ state, type: 'state' }));

  const candidateLifecycleFilterProps = {
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    source: candidateLifecycleSource,
    name: 'lifecycleStatus',
    key: 'lifecycleStatus',
    optionsLarge: true,
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          subCategories: value.subCategories,
          label: value.label,
          value: value.value,
          name: 'lifecycleStatus',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedLifecycleStatus,
    defaultLabel: 'Click to select lifecycle statuses',
  };

  const talentConciergeSource = useMemo(() => {
    const items = talentConciergesAllIds.map((id) => {
      const { attributes: { firstName, lastName } = {} } = talentConciergesById[id] || {};

      return {
        label: `${firstName} ${lastName}`,
        indent: 0,
        optionType: 'role',
        value: {
          type: 'state',
          state: id,
        },
      };
    });

    return items;
  }, [talentConciergesAllIds, talentConciergesById]);

  const selectedTalentConcierge = rainmakersAdminId.map((state) => ({ state, type: 'state' }));

  const talentConciergeProps = {
    defaultIcon: 'caret-down',
    activeIcon: 'caret-up',
    optionsLarge: true,
    source: talentConciergeSource,
    name: 'rainmakersAdminId',
    key: 'rainmakersAdminId',
    isLocation: true,
    onChange: (value) =>
      handleSearchFilterChange({
        target: {
          label: value.label,
          value: value.value,
          name: 'rainmakersAdminId',
          optionType: value.optionType,
        },
      }),
    selectedValues: selectedTalentConcierge,
    defaultLabel: 'Click to select Talent Concierge',
    optionsTall: true,
  };

  return (
    <>
      <div className={styles.SearchBarOne}>
        <DropdownSelectMany {...stateFilterProps} />
        <DropdownSelectMany {...candidateLifecycleFilterProps} />
        <DropdownSelectMany {...talentConciergeProps} />
      </div>
      <div className={styles.SearchBarTwo}>
        <Input {...nameSearchProps} />
        <Input {...emailSearchProps} />
        <Input {...utmSourceSearchProps} />
      </div>
    </>
  );
};

export default ToolboxCandidatesSearchBar;
