import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

import { handleError } from 'utils/common';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import { promiseGetFullCandidate } from 'pages/EmployeePortal/CandidateProfilePages/promises';

import Block from 'components/Block';
import Button from 'components/Button';
import FontIcon from 'components/FontIcon';
import LayoutWithoutSidebar from 'components/LayoutWithoutSidebar';
import Sidebar from 'components/Sidebar';
import TableOverlay from 'components/TableOverlay';

import duck from '../redux';

import SalesPeriodInputs from './components/SalesPeriodInputs';
import CSMSalesPeriodInputs from './components/CSMSalesPeriodInputs';

import styles from './EditSales.scss';

const SALES_PERIODS_TYPES = {
  CSMSalesPeriod: CSMSalesPeriodInputs,
  AESalesPeriod: SalesPeriodInputs,
};

@CandidateAuthHOC()
class EditSales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };
  }

  componentDidMount() {
    const { candidate } = this.props;

    const { id: candidateId } = candidate || {};

    promiseGetFullCandidate({ candidateId }).then(this.onCandidateSuccess).catch(handleError);
  }

  onCandidateSuccess = (candidateProfile) => {
    const {
      actions: { candidateProfilePatchDone },
    } = this.props;

    candidateProfilePatchDone({ candidateProfile });
  };

  handleSalesPeriodInputChange = ({ event, salesPeriodId, workHistoryId }) => {
    const {
      target: { name, value: newValue },
    } = event;

    const {
      actions: { salesPeriodPatchStarted },
    } = this.props;

    salesPeriodPatchStarted({
      name,
      newValue,
      salesPeriodId,
      workHistoryId,
    });
  };

  render() {
    const {
      candidate,
      candidate: { workHistories = [] } = {},
      candidateSaving,
      isProfileLocked,
      location: { search },
    } = this.props;

    const { 'fields[]': fields = [], 'model_index': modelIndex = 0 } = queryString.parse(search);

    const { showAll } = this.state;

    const savingContent = candidateSaving ? (
      <div className={styles.autoSaving}>Saving...</div>
    ) : (
      <div className={styles.autoSaved}>Profile Saved</div>
    );

    const statusContent = isProfileLocked ? (
      <div className={styles.profileLocked}>Profile Locked</div>
    ) : null;

    const saveContent = candidateSaving === undefined ? null : statusContent || savingContent;

    const titleBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: true,
      className: styles.titleBlock,
    };

    const salesBlockProps = {
      largeTitleFont: true,
      noTitlePadding: true,
    };

    const realWorkHistories = workHistories.filter(
      (wh) => wh?.company?.id && wh?.role && !['Non-Sales', 'B2C / Retail'].includes(wh.role)
    );

    const allNonSales = workHistories.every(
      (wh) => !wh.role || ['Non-Sales', 'B2C / Retail'].includes(wh.role)
    );

    const workHistoriesToShow = showAll ? realWorkHistories : realWorkHistories.slice(0, 1);

    const inputContent =
      workHistoriesToShow.length > 0
        ? workHistoriesToShow.map((wh, idx) => {
            let autofocusFields = fields;

            if (fields && typeof fields === 'string') {
              autofocusFields = [fields];
            }

            if (idx !== 0) {
              autofocusFields = [];
            }

            const firstSalesPeriod =
              wh && wh.salesPeriods && wh.salesPeriods.length > 0 ? wh.salesPeriods[0] : null;

            const salesPeriodType = firstSalesPeriod ? firstSalesPeriod.type : null;

            const SalesPeriodComponent = salesPeriodType
              ? SALES_PERIODS_TYPES[salesPeriodType]
              : null;

            const salesPeriodProps = {
              key: wh.id,
              workHistory: {
                attributes: wh,
              },
              handleSalesPeriodInputChange: this.handleSalesPeriodInputChange,
              isProfileLocked,
              autofocusFields,
              modelIndex,
              workHistoryIdx: idx,
            };

            return SalesPeriodComponent ? <SalesPeriodComponent {...salesPeriodProps} /> : null;
          })
        : [];

    const addWorkProps = {
      tertiary: true,
      to: '/candidate/edit-work',
    };
    const emptyBlockProps = {
      addWhiteBG: true,
      boxShadow: true,
      addPadding: false,
      className: styles.salesBlockEmpty,
    };

    const profileLockOverlay = isProfileLocked ? <TableOverlay lockedNotFixed /> : null;

    const showAllButtonProps = {
      primary: true,
      onClick: () => this.setState({ showAll: true }),
    };

    const showMoreIcon = 'caret-down';

    const showAllButtonContainer =
      realWorkHistories.length > 1 && !showAll ? (
        <div className={styles.buttonContainer}>
          <Button {...showAllButtonProps}>
            Show More of Your History{' '}
            <FontIcon className={styles.showMoreIcon} iconName={showMoreIcon} />
          </Button>
        </div>
      ) : null;

    const content =
      realWorkHistories.length > 0 ? (
        <div className={styles.EditorSales}>
          <Block {...titleBlockProps}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Sales Performance</div>
              <div className={styles.saveContent}>{saveContent}</div>
            </div>
          </Block>
          <div className={styles.scrollDiv}>
            <Block {...salesBlockProps}>
              {profileLockOverlay}
              <div className={styles.salesInputContainer}>{inputContent}</div>
            </Block>
            {showAllButtonContainer}
          </div>
        </div>
      ) : (
        <div className={styles.EditorSales}>
          <Block {...titleBlockProps}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Sales Performance</div>
            </div>
          </Block>
          <div className={styles.scrollDiv}>
            <Block {...emptyBlockProps}>
              {profileLockOverlay}
              <div className={styles.salesInputEmptyContainer}>
                <div className={styles.content}>
                  You need to add your work history before adding sales data.
                </div>
                <Button {...addWorkProps}>Add Work History</Button>
              </div>
            </Block>
          </div>
        </div>
      );

    const allNonSalesContent = allNonSales ? (
      <div className={styles.EditorSales}>
        <Block {...titleBlockProps}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Sales Performance</div>
          </div>
        </Block>
        <div className={styles.scrollDiv}>
          <Block {...emptyBlockProps}>
            {profileLockOverlay}
            <div className={styles.salesInputEmptyContainer}>
              <div className={styles.content}>
                Looks like your work history doesn&apos;t include any sales roles, you can skip this
                section.
              </div>
            </div>
          </Block>
        </div>
      </div>
    ) : null;

    const layoutWithoutSidebarProps = {
      content: allNonSales ? allNonSalesContent : content,
    };

    const sidebarProps = {
      type: 'candidateEditor',
      page: 'edit-sales',
      candidate,
    };

    return (
      <React.Fragment>
        <Helmet title="Candidate Editor" />
        <ReadyContainer className={styles.CandidateEditorSales}>
          <Sidebar {...sidebarProps} />
          <LayoutWithoutSidebar {...layoutWithoutSidebarProps} />
        </ReadyContainer>
      </React.Fragment>
    );
  }
}

export default ConnectContainer(duck)(EditSales);
