import { call, select, put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import { segmentCandidateCreatedProfile } from 'segment/eventNames';

import { patchEntity } from 'api/apiEntity';

import { candidateProfilePatchDone } from 'connectors/User/redux/creators';

import { selectUser, selectUTMInfo } from 'connectors/User/redux/selectors';

import { promiseGetDiscourseSSO } from 'connectors/DiscourseSSOLogin/promises';

import { PATCH_CANDIDATE_STARTED } from '../actions';

import { serverErrorSet } from '../creators';

const VALID_STATUSES = ['applied', 'applied_forum', 'set_requirements'];

const SKIP_SALES_INFO_ROLES = ['B2C / Retail', 'Non-Sales'];

const sagaPatchCandidateStarted = function* sagaPatchCandidateStarted() {
  while (true) {
    const { payload: { quickOption = '' } = {} } = yield take(PATCH_CANDIDATE_STARTED);

    const user = yield select(selectUser);

    const {
      currentProfile: { id: candidateId, onboardingStatus, workHistories },
    } = user;

    const latestWorkHistory = workHistories[0] || {};

    const { role: recentRole = null } = latestWorkHistory || {};

    const skipLastPage =
      SKIP_SALES_INFO_ROLES.includes(recentRole) || !recentRole || quickOption === 'forum';

    if (skipLastPage) {
      try {
        const onboardingStatus = quickOption === 'forum' ? 'applied_forum' : 'added_sales_info';

        const args = {
          id: candidateId,
          type: 'candidates',
          data: {
            id: candidateId,
            type: 'candidates',
            attributes: {
              onboardingStatus,
            },
          },
          config: {
            params: {
              camelizeIt: true,
            },
          },
        };

        const {
          data: { candidate },
        } = yield call(patchEntity, args);

        const candidateProfile = {
          ...user.currentProfile,
          ...candidate,
        };

        yield put(candidateProfilePatchDone({ candidateProfile }));

        const { sig, sso } = yield select(selectUTMInfo);

        if (quickOption === 'forum' && sig && sso) {
          try {
            const search = `?sso=${sso}&sig=${sig}`;

            const url = yield call(promiseGetDiscourseSSO, search);

            if (url && window && window.location) {
              window.location.replace(url);
            }
          } catch (error) {
            handleError(error);

            yield put(push('/add-video'));
          }
        } else {
          yield put(push('/add-video'));
        }
      } catch (error) {
        handleError(error);

        const {
          response: {
            data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
          } = {},
        } = error || {};

        yield put(serverErrorSet({ errors }));
      }
    } else {
      try {
        const route = quickOption === 'continue' ? '/set-requirements' : '/add-sales-info';

        yield put(push(route));

        const newOnboardingStatus =
          VALID_STATUSES.includes(onboardingStatus) && quickOption !== 'continue'
            ? 'created_profile'
            : onboardingStatus;

        if (newOnboardingStatus !== onboardingStatus) {
          yield call(trackEvent, { event: segmentCandidateCreatedProfile });
        }

        const args = {
          id: candidateId,
          type: 'candidates',
          data: {
            id: candidateId,
            type: 'candidates',
            attributes: {
              onboardingStatus: newOnboardingStatus,
            },
          },
          config: {
            params: {
              camelizeIt: true,
            },
          },
        };

        const {
          data: { candidate },
        } = yield call(patchEntity, args);

        const candidateProfile = {
          ...user.currentProfile,
          ...candidate,
        };

        yield put(candidateProfilePatchDone({ candidateProfile }));
      } catch (error) {
        handleError(error);

        const {
          response: {
            data: { errors: { full_messages: errors = ['Please try again'] } = {} } = {},
          } = {},
        } = error || {};

        yield put(serverErrorSet({ errors }));
      }
    }
  }
};

export default sagaPatchCandidateStarted;
