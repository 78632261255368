import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import cn from 'classnames';

import { handleError } from 'utils/common';
import { promiseEmailValid } from 'pages/CandidatePortal/CandidateEditorPages/promises';

import Validator from 'connectors/Validator';

import Disclaimer from 'pages/CandidatePortal/OnboardingPages/Apply/components/Disclaimer';

import styles from './ApplyForm.scss';

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'must not be blank';
  }

  if (!values.lastName) {
    errors.lastName = 'must not be blank';
  }

  if (!values.phone) {
    errors.phone = 'must not be blank';
  } else if (!Validator.isPhoneNumber({ newValue: values.phone })) {
    errors.phone = 'must be a valid phone number';
  }

  if (!values.password) {
    errors.password = 'must not be blank';
  } else if (!Validator.isPassword({ newValue: values.password })) {
    errors.password = 'must have at least 8 characters with one number';
  }

  // if (values.linkedin && !Validator.isUrl({ newValue: values.linkedin })) {
  //   errors.linkedin = 'must be a valid URL';
  // }

  return errors;
};

const validateEmail = async (value) => {
  let error;

  if (!Validator.isEmail({ newValue: value })) {
    error = 'invalid email address';
  } else {
    try {
      const response = await promiseEmailValid({ email: value });

      if (response.email) {
        error = 'this email is already taken';
      }
    } catch (err) {
      handleError(err);
    }
  }

  return error;
};

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  linkedin: '',
  password: '',
};

export function ApplyForm({
  title = 'Apply',
  handleSubmit,
  oneLineName = false,
  isEmployerApplyNow = false,
}) {
  const [emailError, setEmailError] = useState(null);

  return (
    <div className={styles.container} data-testid="apply-form">
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          if (emailError) {
            setFieldError('email', emailError);
            setSubmitting(false);
          } else {
            handleSubmit(values).then(() => setSubmitting(false));
          }
        }}
      >
        {({ isSubmitting, handleBlur, setFieldValue }) => (
          <Form>
            {isEmployerApplyNow ? (
              <>
                <div className={styles.applyHeaderEmployer}>Sign Up</div>
                <div className={styles.applySubheader}>
                  Creating a Rainmakers profile means you’ll not have to spend so much energy
                  manually applying to companies. We’ll do the work for you!
                </div>
              </>
            ) : (
              <div className={styles.applyHeader}>
                Rainmakers is the leading career source for sales jobs. Sign up below to get
                started.
              </div>
            )}
            <div className={cn(oneLineName && styles.oneLineName)}>
              <FieldComponent
                title="First Name"
                name="firstName"
                required={true}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              <FieldComponent
                title="Last Name"
                name="lastName"
                required={true}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
            </div>

            <FieldComponent
              title="Email"
              name="email"
              type="email"
              required={true}
              error={emailError}
              handleBlur={async ({ target: { value } }) => {
                const error = await validateEmail(value);
                console.log(error);
                setEmailError(error);
              }}
            />

            <FieldComponent
              title="Your Phone #"
              name="phone"
              type="tel"
              placeholder="Area Code first"
              required={true}
              autoComplete="tel-national"
            />

            <FieldComponent
              title="Your LinkedIn Profile URL"
              name="linkedin"
              placeholder="www.linkedin.com/in/myurl"
              autoComplete="url"
            />

            <FieldComponent
              title="Create Password"
              name="password"
              type="password"
              required={true}
            />

            <div className={styles.disclaimer}>
              <Disclaimer />
            </div>

            <button className={styles.submitBtn} type="submit" disabled={isSubmitting}>
              {title}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const FieldComponent = ({
  title,
  name,
  type = 'text',
  placeholder = '',
  required = false,
  handleBlur,
  setFieldValue,
  error,
  ...otherProps
}) => {
  const fieldProps = {
    type,
    name,
    placeholder,
  };

  if (handleBlur) {
    fieldProps.onBlur = (e) => {
      if (setFieldValue) {
        const { value } = e.currentTarget;
        const upperCaseValue = value.charAt(0).toUpperCase() + value.slice(1);
        setFieldValue(name, upperCaseValue);
      }

      handleBlur(e);
    };
  }

  return (
    <div className={styles.inputContainer}>
      <div className={styles.titleWithError}>
        <h4 className={styles.fieldTitle}>
          {title}
          <span>{required ? ' *' : ''}</span>
        </h4>
        {error ? (
          <div className={styles.errorMessage}>{error}</div>
        ) : (
          <ErrorMessage name={name} component="div" className={styles.errorMessage} />
        )}
      </div>
      <Field {...fieldProps} {...otherProps}>
        {({ field, meta }) => {
          return type === 'tel' ? (
            <InputMask
              {...field}
              {...fieldProps}
              {...otherProps}
              mask="(999) 999-9999"
              maskChar=""
              className={cn(styles.fieldInput, meta.touched && meta.error && styles.hasError)}
            />
          ) : (
            <input
              {...field}
              {...fieldProps}
              {...otherProps}
              className={cn(styles.fieldInput, meta.touched && meta.error && styles.hasError)}
            />
          );
        }}
      </Field>
    </div>
  );
};
