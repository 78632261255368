import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import { redirectHomepage } from 'utils/navHelpers/index';

import ConnectContainer from 'containers/ConnectContainer';

import ApplyForm from 'components/ApplyForm';
import TableOverlay from 'components/TableOverlay';

import getLandingPageData from 'api/landingPages/getLandingPageData';

import { useDesiredRole, toolboxToPreselectedRoles } from 'hooks/useDesiredRole';

import duck from '../redux';

import { PublicLandingPage } from './components/PublicLandingPage/PublicLandingPage';

import applyLogo from './assets/apply-logo.png';

import styles from './ApplyEmployer.scss';

export const ApplyEmployer = (props) => {
  const {
    actions: { applyStarted, saveUTMInfo },
  } = props;

  const location = useLocation();
  const { search } = location;

  const [loading, setLoading] = useState(true);

  const { preselectedRoles: preselectedRolesURL } = useDesiredRole();

  useEffect(() => {
    if (search) {
      const utmInfo = queryString.parse(search);
      saveUTMInfo({ utmInfo });
    }
  }, [saveUTMInfo, search]);

  const { slug } = useParams();

  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLoading(true);
    getLandingPageData(slug)
      .then((data) => {
        setLandingPageData(data);
        setLoading(false);
      })
      .catch((error) => {
        if ([401, 404].includes(error?.response?.status)) {
          window.location = '/apply-now';
        } else {
          setLoading(false);
          throw error;
        }
      });
  }, [slug]);

  const {
    data: {
      public_landing_page: { title, content, picture: { original } = {}, role, desired_role } = {},
    } = {},
  } = landingPageData || {};

  const preselectedRoles = useMemo(
    () =>
      Object.keys(preselectedRolesURL).length > 0
        ? preselectedRolesURL
        : toolboxToPreselectedRoles(role, desired_role),
    [desired_role, preselectedRolesURL, role]
  );

  const handleSubmit = async (values) => {
    const { firstName, lastName, email, linkedin, password, phone } = values;

    const applyData = {
      firstName,
      lastName,
      email,
      linkedinUrl: linkedin,
      password,
      phone,
      preselectedRole: preselectedRoles,
    };

    applyStarted({ applyData });
  };

  const { user, hasSeenApproved, isApproved } = props;

  const redirectPath = redirectHomepage(
    user,
    isApproved,
    hasSeenApproved,
    false,
    undefined,
    preselectedRoles
  );

  const metaTitle = 'Sales Jobs in Tech | Rainmakers';
  const metaDescription =
    'Sign up with Rainmakers to tap into a premium career network and connect with top tech companies in search of dynamic sales professionals. Various sales positions available!';
  const canonicalUrl = `https://${process.env.HOST}/apply-now/`;

  const helmetProps = {
    key: 'helmet',
    title: metaTitle,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:title', content: metaTitle },
      { property: 'og:description', content: metaDescription },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  };

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (loading) return <TableOverlay />;

  return (
    <>
      <Helmet {...helmetProps} />
      <div className={styles.Container}>
        <div className={styles.marketingContainer}>
          <PublicLandingPage title={title} content={content} picture={original} />
        </div>
        <div className={styles.applyFormContainer}>
          <div className={styles.formWrapper}>
            <ApplyForm handleSubmit={handleSubmit} oneLineName={true} isEmployerApplyNow={true} />
          </div>
          <div className={styles.logoWrapper}>
            <img src={applyLogo} alt="reinmakers logo" className={styles.logo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectContainer(duck)(ApplyEmployer);
