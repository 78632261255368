import React from 'react';
import ImageUploader from 'components/ImageUploader';

import EmptyCompanyLogo from 'shared/assets/missing-company-picture.png';

const LandingPageImageUploader = ({ landingPageId, imageUploadStarted, pictureUrl }) => {
  return (
    <ImageUploader
      name="LandingPage"
      entityId={landingPageId}
      imageUploadStarted={imageUploadStarted}
      pictureUrl={pictureUrl}
      emptyLogo={EmptyCompanyLogo}
      rounded={false}
    />
  );
};

export default LandingPageImageUploader;
